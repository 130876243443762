import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import { AuthFormFields, ForgotPasswordSchema } from '../../utils/validation/auth'
import AuthFormField from '../../common/components/AuthFormField'
import AuthConfirmButton from '../../common/components/AuthConfirmButton'
import { PageType } from '../login'
import styles from './styles.module.sass'
import { useSelector } from 'react-redux'
import { loadingActionSelector } from '../../store/loader/loader.selectors'
import { RESET_PASSWORD_REQUEST } from '../../store/auth/auth.types'
import { useActions } from '../../utils/hooks/useActions'
import { resetPasswordRequest } from '../../store/auth/auth.actions'

const ForgotPassword: React.FC = () => {
    const loading = useSelector(loadingActionSelector)([RESET_PASSWORD_REQUEST])
    const [resetPasswordActionDispatch] = useActions([resetPasswordRequest])

    const [emailInvalid, setEmailInvalid] = useState<boolean>(false)
    const [labelError, setLabelError] = useState<string>('')

    return (
        <Formik<AuthFormFields>
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{ email: '' }}
            validationSchema={ForgotPasswordSchema}
            onSubmit={(values, { setSubmitting }) => {
                setEmailInvalid(false)
                setLabelError('')
                setSubmitting(true)
                resetPasswordActionDispatch({
                    data: {
                        email: values.email,
                    },
                    onError: (label: string) => {
                        setLabelError(label)
                        setEmailInvalid(true)
                        setSubmitting(false)
                    },
                })
            }}
        >
            {({ errors, submitForm, isSubmitting }) => (
                <Form className={'width-f'}>
                    <div className={'width-f ta-c'}>
                        <span className={styles.title}>Forgot password</span>
                    </div>
                    <AuthFormField name={'email'} error={errors.email} type={'text'} placeholder={'Email'} disabled={isSubmitting} />
                    {emailInvalid && (
                        <div className={'ta-c width-f mt-4 mb-2'}>
                            <span className={'error-text'}>{labelError || `Something went wrong, try again later`}</span>
                        </div>
                    )}
                    <div className={'mt-6'}>
                        <AuthConfirmButton loading={loading} type={PageType.ForgotPassword} action={submitForm} />
                    </div>
                    <div className={'ta-c width-f'}>
                        <div className={styles.helpText}>
                            Instructions will be sent on your email address, in case it associated with Livemysteps account
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default ForgotPassword
