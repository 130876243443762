import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

interface SearchItemSkeletonProps {
    index: number
}

const SearchItemSkeleton: React.FC<SearchItemSkeletonProps> = ({ index }) => (
    <SkeletonTheme color="#E8E8F0" highlightColor="#FFFFFF">
        <div className={`width-f flex-j-b flex-a-c` + (index < 6 ? ' mb-7' : '')}>
            <Skeleton circle={true} width={50} height={50} className={`mr-7`} style={{ lineHeight: 'unset' }} />
            <div className={'flex-d-c width-f height-f flex-j-a'}>
                <Skeleton width={'90%'} height={7} style={{ lineHeight: 'unset' }} />
                <Skeleton width={'75%'} height={7} style={{ lineHeight: 'unset' }} />
            </div>
        </div>
    </SkeletonTheme>
)

export default SearchItemSkeleton
