import React, { useCallback, useEffect } from 'react'
import styles from './styles.module.sass'
import { User } from '../../../entities/User'
import { useSelector } from 'react-redux'
import { recommendedFriendsSelector } from '../../../store/friends/friends.selectors'
import { useActions } from '../../../utils/hooks/useActions'
import { addUserRequest, getRecommendedRequest } from '../../../store/friends/friends.actions'
import { loadingActionSelector } from '../../../store/loader/loader.selectors'
import { GET_RECOMMENDED_REQUEST } from '../../../store/friends/friends.types'
import UserItem from './components/UserItem'
import RecommendedFriendSkeleton from '../../loaders/RecommendedFriendSkeleton'
import _ from 'lodash'
import { userSelector } from '../../../store/auth/auth.selectors'

const RecommendedFriends: React.FC = () => {
    const myProfile = useSelector(userSelector)
    const recommended = useSelector(recommendedFriendsSelector)
    const [getRecommendedActionDispatch, addUserAction] = useActions([getRecommendedRequest, addUserRequest])
    const loading = useSelector(loadingActionSelector)([GET_RECOMMENDED_REQUEST])

    useEffect(() => {
        if (_.isEmpty(recommended)) {
            getRecommendedActionDispatch()
        }
    }, [getRecommendedActionDispatch, recommended, myProfile])

    const handleAddUser = useCallback(
        (userId: number, callback: () => void) => {
            addUserAction({ id: userId, onRequestEnd: callback })
        },
        [addUserAction]
    )

    return (
        <div className={`${styles.container} shadow`}>
            <span className={styles.title}>People you may know</span>
            <div className={'mt-6'}>
                {loading ? (
                    <div className={'width-f'}>
                        <RecommendedFriendSkeleton />
                        <RecommendedFriendSkeleton />
                        <RecommendedFriendSkeleton />
                        <RecommendedFriendSkeleton isLastItem={true} />
                    </div>
                ) : (
                    recommended.map((people: User) => (
                        <UserItem
                            user={people}
                            addAction={(callback: () => void) => setTimeout(() => handleAddUser(people.id, callback), 0)}
                            key={people.id}
                        />
                    ))
                )}
            </div>
        </div>
    )
}

export default RecommendedFriends
