import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import styles from './styles.module.sass'
import RecommendedFriends from '../../common/components/RecommendedFriends'
import { useActions } from '../../utils/hooks/useActions'
import { getFeedAdditionalRequest, getFeedRequest, likeFeedRequest, selectFeed, unlikeFeedRequest } from '../../store/feed/feed.actions'
import { useSelector } from 'react-redux'
import { feedDataSelector, feedLastUpdateSelector, feedPaginationSelector } from '../../store/feed/feed.selectors'
import FeedCard from './components/FeedCard'
import { loadingActionSelector } from '../../store/loader/loader.selectors'
import { GET_FEED_ADDITIONAL_REQUEST, GET_FEED_REQUEST } from '../../store/feed/feed.types'
import { userSelector } from '../../store/auth/auth.selectors'
import { Feed } from '../../entities/Feed'
import _ from 'lodash'
import { useNavigation } from '../../utils/hooks/useNavigation'
import FeedCardSkeleton from '../../common/loaders/FeedCardSekeleton'
import Friends from '../../common/components/Friends'
import moment from 'moment'
import PerfectScrollbar from 'react-perfect-scrollbar'

const FeedPage: React.FC = () => {
    const scrollRef = useRef(null)
    const navigation = useNavigation()
    const profile = useSelector(userSelector)
    const feed = useSelector(feedDataSelector)
    const pagination = useSelector(feedPaginationSelector)
    const lastUpdated = useSelector(feedLastUpdateSelector)
    const [getFeedAction, likeFeedAction, unlikeFeedAction, selectFeedAction, getAdditionalAction] = useActions([
        getFeedRequest,
        likeFeedRequest,
        unlikeFeedRequest,
        selectFeed,
        getFeedAdditionalRequest,
    ])
    const feedLoading = useSelector(loadingActionSelector)([GET_FEED_REQUEST])
    const additionalLoading = useSelector(loadingActionSelector)([GET_FEED_ADDITIONAL_REQUEST])

    const loadAdditionalPossible = useMemo(() => !additionalLoading && pagination.totalPages !== pagination.currentPage && !feedLoading, [
        additionalLoading,
        pagination,
        feedLoading,
    ])

    const shouldUpdate = useMemo(() => {
        if (lastUpdated) {
            return moment.duration(moment().diff(moment(lastUpdated))).asMinutes() >= 5
        } else {
            return true
        }
    }, [lastUpdated])

    const onScrollEnd = useCallback(
        (event: React.UIEvent<HTMLDivElement>) => {
            const scrolled = event.currentTarget.scrollHeight - event.currentTarget.scrollTop
            const height = event.currentTarget.clientHeight
            const nearBottom = height - scrolled > -10 && height - scrolled < 10
            if (nearBottom && loadAdditionalPossible) {
                getAdditionalAction(pagination.currentPage + 1)
            }
        },
        [getAdditionalAction, loadAdditionalPossible]
    )

    useEffect(() => {
        if ((_.isEmpty(feed) && shouldUpdate) || (!_.isEmpty(feed) && shouldUpdate)) {
            getFeedAction()
        }
    }, [getFeedAction])

    const handleLike = (photo: Feed, callback: () => void) => {
        if (_.find(photo.likes, (o) => o.user.id === profile?.id) !== undefined) {
            unlikeFeedAction({ id: photo.id, onRequestEnd: callback })
        } else {
            likeFeedAction({ id: photo.id, onRequestEnd: callback })
        }
    }

    const handleComment = (photo: Feed) => {
        selectFeedAction(photo)
        navigation.navigateTo(`/feed/${photo.id}`)
    }

    const handleLoaded = () => {
        // @ts-ignore
        scrollRef?.current.updateScroll()
    }

    return (
        <PerfectScrollbar ref={scrollRef} onScroll={onScrollEnd}>
            <div className={styles.container}>
                <div className={`${styles.publicationsContainer}`}>
                    <div className={styles.leftBar}>
                        <RecommendedFriends />
                    </div>
                    <div className={styles.rightBar}>
                        <Friends />
                    </div>
                    {feedLoading ? (
                        <div className={styles.publicationsWrapper}>
                            <FeedCardSkeleton />
                            <FeedCardSkeleton />
                        </div>
                    ) : (
                        feed.map((item) => (
                            <div className={styles.publicationsWrapper} key={item.id}>
                                <FeedCard
                                    item={item}
                                    onLike={(callback) => handleLike(item, callback)}
                                    onComment={() => handleComment(item)}
                                    handleLoaded={handleLoaded}
                                />
                            </div>
                        ))
                    )}
                    {additionalLoading && (
                        <div className={styles.publicationsWrapper}>
                            <FeedCardSkeleton />
                        </div>
                    )}
                </div>
            </div>
        </PerfectScrollbar>
    )
}

export default FeedPage
