import React from 'react'
import styles from './styles.module.sass'
import * as Assets from '../../common/components/DownloadAppBar/assets'
import * as LegsAssets from './assets'

const MobileRedirect: React.FC = () => {
    return (
        <div className={styles.root}>
            <div className={styles.legBox} datatype={'top'}>
                <img className={styles.top} src={LegsAssets.TopLegs} alt="" />
            </div>
            <div className={`flex-d-c flex-a-c flex-j-a ${styles.container}`}>
                <div>
                    <div className={styles.title}>Sorry, but our web version doesn’t support mobile phones :(</div>
                    <div className={styles.title}>Please download the app:</div>
                </div>
                <a className={styles.link} href={'https://play.google.com/store/apps/details?id=com.livemysteps.app'} target={'_blank'}>
                    <div className={styles.marketButton}>
                        <div className={styles.textContainer}>
                            <span className={styles.marketLabel}>GET IT ON</span>
                            <span className={styles.marketName}>Google Play</span>
                        </div>
                        <img className={styles.icon} src={Assets.GooglePlay} alt="Google Play" />
                    </div>
                </a>
                <a className={styles.link} href={'https://apps.apple.com/ru/app/livemysteps-social-travel-map/id1511683700'} target={'_blank'}>
                    <div className={styles.marketButton}>
                        <div className={styles.textContainer}>
                            <span className={styles.marketLabel}>Download on the</span>
                            <span className={styles.marketName}>App Store</span>
                        </div>
                        <img className={styles.icon} src={Assets.AppleStore} alt="App Store" />
                    </div>
                </a>
            </div>
            <div className={styles.legBox} datatype={'bottom'}>
                <img className={styles.bottom} src={LegsAssets.BottomLegs} alt="" />
            </div>
        </div>
    )
}

export default MobileRedirect
