import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import FeedApi from '../../api/feed'
import {
    COMMENT_FEED_REQUEST,
    CommentFeedRequest,
    GET_FEED_ADDITIONAL_REQUEST,
    GET_FEED_BY_ID_REQUEST,
    GET_FEED_REQUEST,
    GET_POPULAR_FEED_REQUEST,
    GetFeedAdditionalRequest,
    GetFeedByIdRequest,
    GetFeedRequest,
    GetPopularFeedRequest,
    LIKE_FEED_REQUEST,
    LikeFeedRequest,
    UNLIKE_FEED_REQUEST,
    UnlikeFeedRequest,
} from './feed.types'
import { startAction, stopAction } from '../loader/loader.actions'
import {
    CommentFeedResponseType,
    GetFeedByIdResponseType,
    GetFeedResponseType,
    LikeFeedResponseType,
    UnlikeFeedResponseType,
} from '../../entities/responses'
import {
    commentFeedSuccess,
    getFeedAdditionalSuccess,
    getFeedByIdSuccess,
    getFeedSuccess,
    getPopularFeedSuccess,
    likeFeedSuccess,
    unlikeFeedSuccess,
} from './feed.actions'

function* getFeed({ type }: GetFeedRequest) {
    yield put(startAction({ name: type }))
    try {
        const response: GetFeedResponseType = yield call(FeedApi.getPublicFeed)
        yield put(getFeedSuccess({ data: response.data, totalPages: response.meta.last_page }))
    } catch (e) {
    } finally {
        yield put(stopAction({ name: type }))
    }
}

function* getAdditionalFeed({ type, payload }: GetFeedAdditionalRequest) {
    yield put(startAction({ name: type }))
    try {
        const response: GetFeedResponseType = yield call(FeedApi.getAdditionalPublicFeed, payload)
        yield put(getFeedAdditionalSuccess(response.data))
    } catch (e) {
    } finally {
        yield put(stopAction({ name: type }))
    }
}

function* getPopularFeed({ type }: GetPopularFeedRequest) {
    yield put(startAction({ name: type }))
    try {
        const response = yield call(FeedApi.getPopularPhoto)
        yield put(getPopularFeedSuccess(response.photo))
    } catch (e) {
    } finally {
        yield put(stopAction({ name: type }))
    }
}

function* getFeedById({ type, payload }: GetFeedByIdRequest) {
    yield put(startAction({ name: type }))
    try {
        const response: GetFeedByIdResponseType = yield call(FeedApi.getFeedById, payload.id)
        yield put(getFeedByIdSuccess(response.photo))
    } catch (e) {
        yield call(payload.onError)
    } finally {
        yield put(stopAction({ name: type }))
    }
}

function* likeFeed({ type, payload }: LikeFeedRequest) {
    yield put(startAction({ name: type }))
    try {
        const response: LikeFeedResponseType = yield call(FeedApi.likeFeed, payload.id)
        yield call(payload.onRequestEnd)
        yield put(likeFeedSuccess({ id: payload.id, data: response.data }))
    } catch (e) {
        yield call(payload.onRequestEnd)
    } finally {
        yield put(stopAction({ name: type }))
    }
}

function* unlikeFeed({ type, payload }: UnlikeFeedRequest) {
    yield put(startAction({ name: type }))
    try {
        const response: UnlikeFeedResponseType = yield call(FeedApi.unlikeFeed, payload.id)
        yield call(payload.onRequestEnd)
        yield put(unlikeFeedSuccess({ id: payload.id, data: response.data }))
    } catch (e) {
        yield call(payload.onRequestEnd)
    } finally {
        yield put(stopAction({ name: type }))
    }
}

function* commentFeed({ type, payload }: CommentFeedRequest) {
    yield put(startAction({ name: type }))
    try {
        const response: CommentFeedResponseType = yield call(FeedApi.commentFeed, payload)
        yield put(commentFeedSuccess(response))
    } catch (e) {
    } finally {
        yield put(stopAction({ name: type }))
    }
}

export default function* feedSagas() {
    return yield all([
        takeLatest(GET_FEED_REQUEST, getFeed),
        takeLatest(GET_FEED_ADDITIONAL_REQUEST, getAdditionalFeed),
        takeLatest(GET_POPULAR_FEED_REQUEST, getPopularFeed),
        takeLatest(GET_FEED_BY_ID_REQUEST, getFeedById),
        takeEvery(LIKE_FEED_REQUEST, likeFeed),
        takeEvery(UNLIKE_FEED_REQUEST, unlikeFeed),
        takeEvery(COMMENT_FEED_REQUEST, commentFeed),
    ])
}
