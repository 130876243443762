import firebase from 'firebase/app'
import 'firebase/messaging'

const firebaseConfig = {
    apiKey: 'AIzaSyDzwl1GO__FfLTU5--YwboWCwfMPq0eSfc',
    authDomain: 'livemysteps-cf2e4.firebaseapp.com',
    databaseURL: 'https://livemysteps-cf2e4.firebaseio.com',
    projectId: 'livemysteps-cf2e4',
    storageBucket: 'livemysteps-cf2e4.appspot.com',
    messagingSenderId: '123527919066',
    appId: '1:123527919066:web:e0d574fcaf107d2ffafce3',
    measurementId: 'G-7Z486NW7BW',
}

firebase.initializeApp(firebaseConfig)

let messaging: firebase.messaging.Messaging | null = null

if (firebase.messaging.isSupported()) {
    messaging = firebase.messaging()
}

export { messaging }
