import React from 'react'
import * as Assets from './assets'
import styles from './styles.module.sass'

const DownloadAppBar: React.FC = () => {
    return (
        <div className={`flex-j-b ${styles.container}`}>
            <a className={styles.link} href={'https://play.google.com/store/apps/details?id=com.livemysteps.app'} target={'_blank'}>
                <div className={styles.marketButton}>
                    <div className={styles.textContainer}>
                        <span className={styles.marketLabel}>GET IT ON</span>
                        <span className={styles.marketName}>Google Play</span>
                    </div>
                    <img className={styles.icon} src={Assets.GooglePlay} alt="Google Play" />
                </div>
            </a>
            <a className={styles.link} href={'https://apps.apple.com/ru/app/livemysteps-social-travel-map/id1511683700'} target={'_blank'}>
                <div className={styles.marketButton}>
                    <div className={styles.textContainer}>
                        <span className={styles.marketLabel}>Download on the</span>
                        <span className={styles.marketName}>App Store</span>
                    </div>
                    <img className={styles.icon} src={Assets.AppleStore} alt="App Store" />
                </div>
            </a>
        </div>
    )
}

export default DownloadAppBar
