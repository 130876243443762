import { ActionCreator, bindActionCreators } from 'redux'
import { useDispatch } from 'react-redux'
import { useMemo } from 'react'

// tslint:disable-next-line:no-any
export const useActions = (actions: ActionCreator<any>[]) => {
    const dispatch = useDispatch()
    return useMemo(() => {
        if (Array.isArray(actions)) {
            return actions.map((action) => bindActionCreators(action, dispatch))
        }
        return bindActionCreators(actions, dispatch)
        // eslint-disable-next-line
    }, [dispatch])
}
