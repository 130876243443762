import React from 'react'
import { useNavigation } from '../../utils/hooks/useNavigation'
import links from '../../utils/links'

export type AuthContextType = {
    onAuthSuccess: () => void
}

export const AuthContext = React.createContext<AuthContextType>({
    onAuthSuccess: () => {},
})
export const AuthProvider: React.FC = ({ children }) => {
    const navigation = useNavigation()

    return <AuthContext.Provider value={{ onAuthSuccess: () => navigation.navigateTo(links.feed) }}>{children}</AuthContext.Provider>
}

export default AuthProvider
