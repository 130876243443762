import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styles from './styles.module.sass'
import Avatar from 'react-avatar'
import { useSelector } from 'react-redux'
import { selectedUserSelector } from '../../store/friends/friends.selectors'
import { renderProfileName, renderUserName } from '../../utils/common'
import { Link, Redirect, useParams } from 'react-router-dom'
import { useActions } from '../../utils/hooks/useActions'
import { addUserRequest, getUserByIdRequest } from '../../store/friends/friends.actions'
import { loadingActionSelector } from '../../store/loader/loader.selectors'
import { GET_USER_BY_ID_REQUEST } from '../../store/friends/friends.types'
import * as Assets from './assets'
import useIsInSentPending from '../../utils/hooks/useIsInSentPending'
import { User } from '../../entities/User'
import { userSelector } from '../../store/auth/auth.selectors'
import UserProfileSkeleton from '../../common/loaders/UserProfileSkeleton'
import { useNavigation } from '../../utils/hooks/useNavigation'
import { countriesSelector } from '../../store/countries/countries.selectors'
import _ from 'lodash'
import { GET_COUNTRIES_REQUEST } from '../../store/countries/countries.types'
import Friends from '../../common/components/Friends'
import PerfectScrollbar from 'react-perfect-scrollbar'

const UserPage: React.FC = () => {
    const { navigateTo } = useNavigation()
    const { userId } = useParams<{ userId: string }>()
    const { user, myFriend } = useSelector(selectedUserSelector)
    const myProfile = useSelector(userSelector)
    const [getUserAction, addUserAction] = useActions([getUserByIdRequest, addUserRequest])
    const isSentPending = useIsInSentPending(Number(userId))
    const loading = useSelector(loadingActionSelector)([GET_USER_BY_ID_REQUEST])
    const countryLoading = useSelector(loadingActionSelector)([GET_COUNTRIES_REQUEST])
    const isMyProfile = useMemo(() => String(myProfile?.id) === userId, [myProfile, userId])
    const isBlockedMe = useMemo(() => user?.blockedUsers.map((u) => u.id).includes(myProfile!.id), [myProfile, user])
    const countries = useSelector(countriesSelector)

    const userCountry = useMemo(() => {
        const country = _.find(countries, { id: user?.country_id })
        // @ts-ignore
        return country?.name || ''
    }, [countries, user])

    const location = useMemo(() => {
        return (user?.city || '') + (userCountry && user?.city ? `, ` : '') + (userCountry ? userCountry : '')
    }, [userCountry, user])

    const [disabled, setDisabled] = useState<boolean>()

    const [scrollEl, setScrollEl] = useState<HTMLElement>()

    const scrollToTop = () => {
        if (scrollEl) {
            scrollEl.scrollTop = 0
        }
    }

    useEffect(() => {
        scrollToTop()
    }, [])

    useEffect(() => {
        if (myProfile) {
            getUserAction({ id: userId, onError: () => navigateTo('/feed') })
        }
    }, [userId, myProfile])

    const handleAddUser = useCallback(() => {
        setDisabled(true)
        addUserAction({ id: userId, onRequestEnd: () => setDisabled(false) })
    }, [addUserAction, userId, setDisabled])

    if (isMyProfile) {
        return <Redirect to={'/profile'} />
    }

    const profileContent = () =>
        isBlockedMe ? (
            <div className={'width-f flex-d-c flex-a-c flex-j-a'}>
                <div className={styles.blockBox}>
                    <img src={Assets.BlockSteps} className={styles.img} alt="Block steps" />
                    <span>This user has blocked you :(</span>
                </div>
                <div className={styles.privatTextBox}>
                    <span className={styles.text}>This account is private, in order to see photos you need to add this person as a friend.</span>
                </div>
            </div>
        ) : !myFriend ? (
            <div className={'width-f flex-d-c flex-a-c flex-j-a'}>
                <img src={Assets.Lock} alt={'Lock'} width={237} />
                <div className={styles.privatTextBox}>
                    <span className={styles.text}>This account is private, in order to see photos you need to add this person as a friend.</span>
                </div>
            </div>
        ) : (
            <div className={`width-f ${styles.friendsBox}`}>
                <div className={styles.title}>{`${renderUserName(user!, true)} friends: ${user?.friends.length}`}</div>
                <div className={styles.usersContainer}>
                    {user?.friends.map((friend: User) => (
                        <div className={`${styles.userItem}`} key={friend.id}>
                            <Link className={`${styles.link} flex-centred flex-d-c`} to={`/user/${friend.id}`} key={friend.id}>
                                <Avatar className={styles.avatar} src={friend.avatar} name={renderUserName(friend)} size={'78'} />
                                <span className={styles.name}>{renderUserName(friend)}</span>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        )

    return (
        <PerfectScrollbar
            containerRef={(ref: React.SetStateAction<HTMLElement | undefined>) => {
                setScrollEl(ref)
            }}
        >
            <div className={styles.publicationsContainer}>
                {loading || countryLoading ? (
                    <UserProfileSkeleton pageType={'user'} scrollToTop={scrollToTop} />
                ) : (
                    user && (
                        <>
                            <div className={`${styles.leftBar} shadow`} style={{ minHeight: isBlockedMe ? 'unset' : '247px' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <div className={`${styles.userInfo} flex-d-r flex-a-c`} style={{ margin: '0 auto' }}>
                                        <Avatar
                                            src={user?.avatar}
                                            name={renderUserName(user!)}
                                            size={'78'}
                                            round={true}
                                            className={`${styles.avatar} mr-4`}
                                        />
                                        <div className={`flex-d-c flex-j-c ${styles.userNameWrap}`}>
                                            <span className={styles.userName}>
                                                {user.nickname.length > 20 ? `${user.nickname.slice(0, 20)}...` : user.nickname}
                                            </span>
                                            <div className={'flex-d-c'}>
                                                <span className={styles.subText}>{renderProfileName(user!)}</span>
                                                <span className={styles.subText}>{location}</span>
                                            </div>
                                        </div>
                                    </div>
                                    {myFriend && !isBlockedMe && (
                                        <div className={`${styles.userInfo} height-f flex-a-c mt-9 mb-3`}>
                                            <div className={`flex-d-r flex-a-c flex-j-s ${styles.friendIconBox}`}>
                                                <img className={`mr-2 ${styles.friendsIcon}`} src={Assets.Friend} width={26} alt="Friend ico" />
                                                <span>Friends</span>
                                            </div>
                                            <span className={styles.friendsBadge}>{`${user?.friends.length}`}</span>
                                        </div>
                                    )}
                                </div>
                                {!myFriend && !isBlockedMe && (
                                    <div className={'flex-d-c width-f mt-8'}>
                                        {isSentPending ? (
                                            <button className={`${styles.addButton} flex-centred`} style={{ backgroundColor: '#15593E' }} disabled>
                                                Sent
                                            </button>
                                        ) : (
                                            !isBlockedMe && (
                                                <button className={`${styles.addButton}`} onClick={handleAddUser} disabled={disabled}>
                                                    Add
                                                </button>
                                            )
                                        )}
                                    </div>
                                )}
                            </div>
                            <div
                                className={`${styles.mainBox} ${!!user?.friends && !isBlockedMe ? '' : styles.privatePopup} shadow`}
                                style={
                                    ((!myFriend || isBlockedMe) && {
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }) ||
                                    {}
                                }
                            >
                                {profileContent()}
                            </div>
                            <div className={styles.rightBar}>
                                <Friends scrollToTop={scrollToTop} />
                            </div>
                        </>
                    )
                )}
            </div>
        </PerfectScrollbar>
    )
}

export default UserPage
