import axios, { AxiosResponse, Method } from 'axios'
import Cookies from 'universal-cookie'
import { logout } from '../store/auth/auth.actions'
import store from '../store/configureStore'
import config from './config'

type HeaderType = { [key: string]: string }

export interface IRequestParams {
    url: string
    method?: Method
    headers?: HeaderType
    // tslint:disable-next-line:no-any
    data?: any
    auth?: boolean
}

export interface IHttpConfig {
    apiURL?: string
    headers?: HeaderType
}

class HttpService {
    client = axios.interceptors.response.use(
        (response) => {
            return response
        },
        (error) => {
            if (error.response.status === 401) {
                store.dispatch(logout())
            }
            return Promise.reject(error)
        }
    )

    config: IHttpConfig = {
        apiURL: config.BACKEND_URL,
        headers: {
            Accept: 'application/json, multipart/form-data',
            'Access-Control-Allow-Origin': '*',
            'Content-Encoding': 'gzip',
        },
    }

    constructor(params: IHttpConfig) {
        this.config = { ...this.config, ...params }
    }

    getHeaders = (headers: HeaderType | undefined, auth?: boolean): HeaderType => {
        const cookies = new Cookies()
        const token = cookies.get('token')

        const headersObj = token && auth ? { ...headers, Authorization: `Bearer ${token}` } : { ...headers }

        return Object.assign({}, this.config.headers, headersObj)
    }

    getURL = (url: string) => {
        return `${this.config.apiURL}/${url}`
    }

    request = (params: IRequestParams) => {
        const headers = this.getHeaders(params.headers, params.auth)
        const url = this.getURL(params.url)

        const requestParams = {
            url,
            headers,
            method: params.method,
            data: params.data,
        }

        // tslint:disable-next-line:no-any
        return axios.request(requestParams).then((response: AxiosResponse<any>) => response.data)
    }

    // tslint:disable-next-line:no-any
    post = (url: string, data?: any, auth: boolean = true) => {
        return this.request({ url, data, auth, method: 'post' })
    }

    // tslint:disable-next-line:no-any
    put = (url: string, data?: any, auth: boolean = true) => {
        return this.request({ url, data, auth, method: 'put' })
    }

    // tslint:disable-next-line:no-any
    patch = (url: string, data?: any, auth: boolean = true) => {
        return this.request({ url, data, auth, method: 'patch' })
    }

    // tslint:disable-next-line:no-any
    get = (url: string, data?: any, auth: boolean = true) => {
        return this.request({ url, data, auth, method: 'get' })
    }

    // tslint:disable-next-line:no-any
    delete = (url: string, data?: any, auth: boolean = true) => {
        return this.request({ url, data, auth, method: 'delete' })
    }
}

export default HttpService
