import _ from 'lodash'
import Cookies from 'universal-cookie'
import { useMemo } from 'react'

const useCookies = () => {
    const cookies = new Cookies()
    return useMemo(
        () => ({
            tokenExist: !_.isEmpty(cookies.get('token')),
            removeUser: () => {
                cookies.remove('user')
            },
            setToken: (token: string) => {
                cookies.set('token', token)
            },
            getToken: () => cookies.get('token'),
        }),
        [cookies]
    )
}

export default useCookies
