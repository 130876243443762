import { LoginRequestType, RegisterRequestType, ResetPasswordRequestType } from '../../entities/requests'
import { User } from '../../entities/User'
import { RegisterResponseType } from '../../entities/responses'
import { Action } from 'redux'
import { GoogleLoginResponse } from 'react-google-login'
import { CredentialsInvalidLabels } from '../../pages/register'

export const AUTH_INIT = '@@auth/AUTH_INIT'
export const LOGIN_REQUEST = `@@auth/LOGIN_REQUEST`
export const LOGIN_SUCCESS = `@@auth/LOGIN_SUCCESS`
export const REGISTER_REQUEST = `@@auth/REGISTER_REQUEST`
export const REGISTER_SUCCESS = `@@auth/REGISTER_SUCCESS`
export const RESET_PASSWORD_REQUEST = `@@auth/RESET_PASSWORD_REQUEST`
export const GOOGLE_AUTH_REQUEST = '@@auth/GOOGLE_AUTH_REQUEST'
export const FACEBOOK_AUTH_REQUEST = '@@auth/FACEBOOK_AUTH_REQUEST'
export const GET_USER_REQUEST = '@@auth/GET_USER_REQUEST'
export const GET_USER_SUCCESS = '@@auth/GET_USER_SUCCESS'
export const CREATE_DEVICE_TOKEN_REQUEST = '@@auth/CREATE_DEVICE_TOKEN_REQUEST'
export const CREATE_DEVICE_TOKEN_SUCCESS = '@@auth/CREATE_DEVICE_TOKEN_SUCCESS'
export const DELETE_DEVICE_TOKEN_REQUEST = '@@auth/DELETE_DEVICE_TOKEN_REQUEST'
export const DELETE_DEVICE_TOKEN_SUCCESS = '@@auth/DELETE_DEVICE_TOKEN_SUCCESS'
export const LOGOUT = '@@auth/LOGOUT'
export const LOGOUT_SUCCESS = '@@auth/LOGOUT_SUCCESS'

export interface AuthState {
    token: string | null
    deviceToken: string | null
    user: User | null
}

export interface AuthInit extends Action {
    type: typeof AUTH_INIT
    payload: { onSuccess: () => void; onTooManyRequests: () => void }
}

export interface LoginRequest extends Action {
    type: typeof LOGIN_REQUEST
    payload: {
        data: LoginRequestType
        onSuccess: () => void
        onError: () => void
    }
}

export interface GoogleAuthRequest extends Action {
    type: typeof GOOGLE_AUTH_REQUEST
    payload: {
        data: GoogleLoginResponse
        onSuccess: () => void
        onError: () => void
    }
}

export interface FacebookAuthRequest extends Action {
    type: typeof FACEBOOK_AUTH_REQUEST
    payload: {
        data: string
        onError: () => void
        onSuccess: () => void
    }
}

export interface LoginSuccess extends Action {
    type: typeof LOGIN_SUCCESS
    payload: { token: string; user: User }
}

export interface RegisterRequest extends Action {
    type: typeof REGISTER_REQUEST
    payload: {
        data: RegisterRequestType
        onSuccess: () => void
        onError: (label?: CredentialsInvalidLabels) => void
    }
}

export interface RegisterSuccess extends Action {
    type: typeof REGISTER_SUCCESS
    payload: RegisterResponseType
}

export interface ResetPasswordRequest extends Action {
    type: typeof RESET_PASSWORD_REQUEST
    payload: {
        data: ResetPasswordRequestType
        onError: (label?: string) => void
    }
}

export interface GetUserRequest extends Action {
    type: typeof GET_USER_REQUEST
}

export interface GetUserSuccess extends Action {
    type: typeof GET_USER_SUCCESS
    payload: User
}

export interface CreateDeviceTokenRequest extends Action {
    type: typeof CREATE_DEVICE_TOKEN_REQUEST
    payload: string
}

export interface CreateDeviceTokenSuccess extends Action {
    type: typeof CREATE_DEVICE_TOKEN_SUCCESS
    payload: string
}

export interface DeleteDeviceTokenRequest extends Action {
    type: typeof DELETE_DEVICE_TOKEN_REQUEST
    payload: string
}

export interface DeleteDeviceTokenSuccess extends Action {
    type: typeof DELETE_DEVICE_TOKEN_SUCCESS
}

export interface Logout extends Action {
    type: typeof LOGOUT
}

export interface LogoutSuccess extends Action {
    type: typeof LOGOUT_SUCCESS
}

export type AuthActions =
    | AuthInit
    | LoginRequest
    | GoogleAuthRequest
    | FacebookAuthRequest
    | LoginSuccess
    | RegisterRequest
    | RegisterSuccess
    | ResetPasswordRequest
    | GetUserRequest
    | GetUserSuccess
    | CreateDeviceTokenRequest
    | CreateDeviceTokenSuccess
    | DeleteDeviceTokenRequest
    | DeleteDeviceTokenSuccess
    | Logout
    | LogoutSuccess
