import { all, call, put, takeLatest, takeEvery, select } from 'redux-saga/effects'
import {
    ACCEPT_FRIENDSHIP_REQUEST,
    AcceptFriendshipRequest,
    ADD_USER_REQUEST,
    AddUserRequest,
    CANCEL_SENT_REQUEST,
    CancelSentRequest,
    GET_FRIENDS_REQUEST,
    GET_RECEIVED_REQUESTS_REQUEST,
    GET_RECOMMENDED_REQUEST,
    GET_SENT_REQUESTS_REQUEST,
    GET_USER_BY_ID_REQUEST,
    GetFriendsRequest,
    GetReceivedRequestsRequest,
    GetRecommendedRequest,
    GetSentRequestsRequest,
    GetUserByIdRequest,
    SEARCH_USER_REQUEST,
    SearchUserRequest,
} from './friends.types'
import { startAction, stopAction } from '../loader/loader.actions'
import FriendsApi from '../../api/friends'
import {
    acceptFriendshipSuccess,
    addUserSuccess,
    cancelSentSuccess,
    getFriendsSuccess,
    getReceivedRequestsSuccess,
    getRecommendedSuccess,
    getSentRequestsSuccess,
    getUserByIdSuccess,
    searchUserSuccess,
} from './friends.actions'
import { userSelector } from '../auth/auth.selectors'
import {
    AddUserResponseType,
    GetReceivedRequestsResponseType,
    GetRecommendedFriendsResponseType,
    GetSentRequestsResponseType,
} from '../../entities/responses'
import { User } from '../../entities/User'
import _ from 'lodash'

function* getFriends({ type, payload }: GetFriendsRequest) {
    yield put(startAction({ name: type }))
    try {
        const user = yield select(userSelector)
        const response = yield call(FriendsApi.getFriends, user.id)
        yield call(payload.onSuccess)
        yield put(getFriendsSuccess(response.friends))
    } catch (e) {
    } finally {
        yield put(stopAction({ name: type }))
    }
}

function* getRecommended({ type }: GetRecommendedRequest) {
    yield put(startAction({ name: type }))
    try {
        const response: GetRecommendedFriendsResponseType = yield call(FriendsApi.getRecommended)
        if (!_.isEmpty(response.users)) {
            yield put(getRecommendedSuccess(response.users.slice(0, 4)))
        }
    } catch (e) {
    } finally {
        yield put(stopAction({ name: type }))
    }
}

function* getUserByIdRequest({ type, payload }: GetUserByIdRequest) {
    yield put(startAction({ name: type }))
    try {
        const { id } = yield select(userSelector)
        const response = yield call(FriendsApi.getUserById, payload.id)
        yield put(getUserByIdSuccess({ user: response.user, myId: id }))
    } catch (e) {
        yield call(payload.onError)
    } finally {
        yield put(stopAction({ name: type }))
    }
}

function* addUser({ type, payload }: AddUserRequest) {
    yield put(startAction({ name: type, params: payload }))
    try {
        const response: AddUserResponseType = yield call(FriendsApi.addUser, payload.id)
        yield put(addUserSuccess(response.friendship))
        yield call(payload.onRequestEnd)
    } catch (e) {
        yield call(payload.onRequestEnd)
    } finally {
        yield put(stopAction({ name: type, params: payload }))
    }
}

function* acceptFriendship({ type, payload }: AcceptFriendshipRequest) {
    yield put(startAction(type))
    try {
        yield call(FriendsApi.acceptFriendship, payload.user.id)
        yield put(acceptFriendshipSuccess(payload.user))
        yield call(payload.onSuccess)
    } catch (e) {
    } finally {
        yield put(stopAction(type))
    }
}

function* cancelSentRequest({ type, payload }: CancelSentRequest) {
    yield put(startAction({ name: type }))
    try {
        yield call(FriendsApi.cancelSentRequest, payload)
        yield put(cancelSentSuccess(payload))
    } catch (e) {
    } finally {
        yield put(stopAction({ name: type }))
    }
}

function* getSentRequests({ type }: GetSentRequestsRequest) {
    yield put(startAction({ name: type }))
    try {
        const response: GetSentRequestsResponseType = yield call(FriendsApi.getPendingSent)
        yield put(getSentRequestsSuccess(response.sentPendingRequests))
    } catch (e) {
    } finally {
        yield put(stopAction({ name: type }))
    }
}

function* getReceivedRequests({ type }: GetReceivedRequestsRequest) {
    yield put(startAction({ name: type }))
    try {
        const response: GetReceivedRequestsResponseType = yield call(FriendsApi.getPendingReceived)
        yield put(getReceivedRequestsSuccess(response.receivedPendingRequests))
    } catch (e) {
    } finally {
        yield put(stopAction({ name: type }))
    }
}

function* searchUser({ type, payload }: SearchUserRequest) {
    yield put(startAction({ name: type }))
    try {
        const { id } = yield select(userSelector)
        const response = yield call(FriendsApi.search, payload.keyword)
        yield put(searchUserSuccess(response.data.filter((user: User) => user.id !== id)))
        yield call(payload.onSuccess)
    } catch (e) {
        yield put(searchUserSuccess([]))
    } finally {
        yield put(stopAction({ name: type }))
    }
}

export default function* friendsSagas() {
    return yield all([
        takeLatest(GET_FRIENDS_REQUEST, getFriends),
        takeLatest(GET_RECOMMENDED_REQUEST, getRecommended),
        takeLatest(GET_USER_BY_ID_REQUEST, getUserByIdRequest),
        takeEvery(ADD_USER_REQUEST, addUser),
        takeEvery(ACCEPT_FRIENDSHIP_REQUEST, acceptFriendship),
        takeLatest(CANCEL_SENT_REQUEST, cancelSentRequest),
        takeLatest(GET_SENT_REQUESTS_REQUEST, getSentRequests),
        takeLatest(GET_RECEIVED_REQUESTS_REQUEST, getReceivedRequests),
        takeLatest(SEARCH_USER_REQUEST, searchUser),
    ])
}
