import React, { useContext, useState } from 'react'
import styles from './styles.module.sass'
import { AuthFormFields, SignInSchema } from '../../utils/validation/auth'
import { Form, Formik } from 'formik'
import AuthFormField from '../../common/components/AuthFormField'
import { Link } from 'react-router-dom'
import links from '../../utils/links'
import AuthConfirmButton from '../../common/components/AuthConfirmButton'
import { useActions } from '../../utils/hooks/useActions'
import { googleAuthRequest, loginRequest } from '../../store/auth/auth.actions'
import { FACEBOOK_AUTH_REQUEST, GOOGLE_AUTH_REQUEST, LOGIN_REQUEST } from '../../store/auth/auth.types'
import { useSelector } from 'react-redux'
import { loadingActionSelector } from '../../store/loader/loader.selectors'
import { AuthContext, AuthContextType } from '../../common/contexts/authContext'
import GoogleLogin from 'react-google-login'
import { socialAuthConfig } from '../../utils/socialAuth'
import SocialMediaButton from '../../common/components/SocialMediaButton'
import * as LoginAssets from './assets'

export enum PageType {
    Login = 'Login',
    SignUp = 'Sign Up',
    ForgotPassword = 'Forgot Password',
}

const Login: React.FC = () => {
    const [loginActionDispatch] = useActions([loginRequest])
    const loading = useSelector(loadingActionSelector)([LOGIN_REQUEST, GOOGLE_AUTH_REQUEST, FACEBOOK_AUTH_REQUEST])
    const { onAuthSuccess } = useContext<AuthContextType>(AuthContext)

    const [credentialsInvalid, setCredentialsInvalid] = useState<boolean>(false)

    const [googleAuthActionDispatch] = useActions([googleAuthRequest])

    return (
        <>
            <Formik<AuthFormFields>
                validateOnBlur={false}
                validateOnChange={false}
                initialValues={{ email: '', password: '' }}
                validationSchema={SignInSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setCredentialsInvalid(false)
                    setSubmitting(true)
                    loginActionDispatch({
                        data: values,
                        onError: () => {
                            setCredentialsInvalid(true)
                            setSubmitting(false)
                        },
                        onSuccess: onAuthSuccess,
                    })
                }}
            >
                {({ errors, submitForm, isSubmitting }) => (
                    <Form className={'width-f'} onSubmit={submitForm}>
                        <AuthFormField name={'email'} error={errors.email} type={'text'} placeholder={'Email'} disabled={isSubmitting} />
                        <AuthFormField name={'password'} error={errors.password} type={'password'} placeholder={'Password'} disabled={isSubmitting} />
                        {credentialsInvalid && (
                            <div className={`${styles.forgotPasswordBox} width-f`}>
                                <div style={{ textAlign: 'right', width: '35%' }} className={'error-text'}>
                                    Invalid data
                                </div>
                                <div className={'mr-5 ml-5'}>|</div>
                                <div style={{ width: '35%' }}>
                                    <Link to={links.forgotPassword} datatype={'active'} className={styles.forgotPasswordText}>
                                        {`Forgot password`}
                                    </Link>
                                </div>
                            </div>
                        )}
                        <div className={`${credentialsInvalid ? 'mt-4' : 'mt-8'}`}>
                            <AuthConfirmButton loading={loading} type={PageType.Login} action={submitForm} />
                        </div>
                    </Form>
                )}
            </Formik>
            <div className={`${styles.orBox} width-f`}>
                <div className={styles.horizontalLine} />
                <span>or</span>
                <div className={styles.horizontalLine} />
            </div>
            <div className={`${styles.fullWidth} ${styles.flexJustifyAround}`}>
                <GoogleLogin
                    clientId={socialAuthConfig.google.appId as string}
                    onSuccess={(response) =>
                        googleAuthActionDispatch({
                            data: response,
                            onError: () => {},
                            onSuccess: onAuthSuccess,
                        })
                    }
                    render={({ onClick }) => <SocialMediaButton action={onClick} label={'Google'} icon={LoginAssets.Google} />}
                />
            </div>
        </>
    )
}

export default Login
