import React from 'react'
import { Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { userSelector } from '../../store/auth/auth.selectors'
import styles from '../mobileRedirect/styles.module.sass'
import * as LegsAssets from '../mobileRedirect/assets'
import links from '../../utils/links'

const TooManyAttempts: React.FC = () => {
    const user = useSelector(userSelector)

    if (user) {
        return <Redirect to={links.feed} />
    }

    return (
        <div className={styles.root}>
            <div className={styles.legBox} datatype={'top'}>
                <img className={styles.top} src={LegsAssets.TopLegs} alt="" style={{ width: '20%' }} />
            </div>
            <div className={`flex-d-c flex-a-c flex-j-a ${styles.container}`}>
                <div>
                    <div className={styles.title}>Too many attempts to refresh page :(</div>
                    <div className={styles.title}>Please try again in a minute</div>
                </div>
            </div>
            <div className={styles.legBox} datatype={'bottom'}>
                <img className={styles.bottom} src={LegsAssets.BottomLegs} alt="" style={{ width: '20%' }} />
            </div>
        </div>
    )
}

export default TooManyAttempts
