import { all, call, put, takeLatest } from 'redux-saga/effects'
import { GET_PRIVACY_REQUEST, GET_TERMS_REQUEST, GetPrivacyRequest, GetTermsRequest } from './privacy.types'
import { startAction, stopAction } from '../loader/loader.actions'
import PagesApi from '../../api/privacy'
import { getPrivacySuccess, getTermsSuccess } from './privacy.actions'

function* getPrivacy({ type }: GetPrivacyRequest) {
    yield put(startAction({ name: type }))
    try {
        const response = yield call(PagesApi.getPrivacy)
        yield put(getPrivacySuccess(response.page.body))
    } catch (e) {
    } finally {
        yield put(stopAction({ name: type }))
    }
}

function* getTerms({ type }: GetTermsRequest) {
    yield put(startAction({ name: type }))
    try {
        const response = yield call(PagesApi.getTerms)
        yield put(getTermsSuccess(response.page.body))
    } catch (e) {
    } finally {
        yield put(stopAction({ name: type }))
    }
}

export default function* friendsSagas() {
    return yield all([takeLatest(GET_PRIVACY_REQUEST, getPrivacy), takeLatest(GET_TERMS_REQUEST, getTerms)])
}
