import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
    AUTH_INIT,
    AuthInit,
    CREATE_DEVICE_TOKEN_REQUEST,
    CreateDeviceTokenRequest,
    DELETE_DEVICE_TOKEN_REQUEST,
    DeleteDeviceTokenRequest,
    FACEBOOK_AUTH_REQUEST,
    FacebookAuthRequest,
    GET_USER_REQUEST,
    GetUserRequest,
    GOOGLE_AUTH_REQUEST,
    GoogleAuthRequest,
    LOGIN_REQUEST,
    LoginRequest,
    LOGOUT,
    Logout,
    REGISTER_REQUEST,
    RegisterRequest,
    RESET_PASSWORD_REQUEST,
    ResetPasswordRequest,
} from './auth.types'
import { startAction, stopAction } from '../loader/loader.actions'
import { GetUserResponseType, LoginResponseType, RegisterResponseType } from '../../entities/responses'
import AuthApi from '../../api/auth'
import {
    getUserRequest,
    getUserSuccess,
    logoutSuccess,
    registerSuccess,
    logout as logoutAction,
    createDeviceTokenSuccess,
    deleteDeviceTokenSuccess,
} from './auth.actions'
import { toast } from 'react-toastify'
import Cookies from 'universal-cookie'
import moment from 'moment'
import { getReceivedRequestsRequest, getSentRequestsRequest, resetFriends } from '../friends/friends.actions'
import _ from 'lodash'
import { CredentialsInvalidLabels } from '../../pages/register'
import { getCountriesRequest } from '../countries/countries.action'
import links from '../../utils/links'

function* authInit({ type }: AuthInit) {
    yield put(startAction({ name: type }))
    const cookies = new Cookies()
    try {
        yield put(getCountriesRequest())
        const token = cookies.get('token')
        if (token) {
            const response: GetUserResponseType = yield call(AuthApi.getUser)
            yield put(getUserSuccess(response.user))
            yield put(getReceivedRequestsRequest())
            yield put(getSentRequestsRequest())
            yield put(getCountriesRequest())
        }
    } catch (e) {
        if (e?.response?.status === 429) {
            if (!(`${process.env.REACT_APP_HOST_URL}${links.tooManyAttempts}` === window.location.href)) {
                window.location.assign(`${process.env.REACT_APP_HOST_URL}${links.tooManyAttempts}`)
            }
        } else {
            yield put(logoutAction())
        }
    } finally {
        yield put(stopAction({ name: type }))
    }
}

function* login({ type, payload }: LoginRequest) {
    yield put(startAction({ name: type }))
    const cookies = new Cookies()
    try {
        const response: LoginResponseType = yield call(AuthApi.login, payload.data)
        cookies.set('token', response.token, { expires: moment().add(10, 'd').toDate(), path: '/' })
        yield put(getUserRequest())
        yield call(payload.onSuccess)
        yield put(getCountriesRequest())
    } catch (e) {
        yield call(payload.onError)
    } finally {
        yield put(stopAction({ name: type }))
    }
}

function* googleAuth({ type, payload }: GoogleAuthRequest) {
    yield put(startAction({ name: type }))
    const cookies = new Cookies()
    try {
        const response: LoginResponseType = yield call(AuthApi.googleAuth, {
            token: payload.data.getAuthResponse().id_token,
            device: 'web',
        })
        cookies.set('token', response.token, { path: '/' })
        yield put(getUserRequest())
        yield call(payload.onSuccess)
        yield call(toast.success, 'Login successful')
        yield put(getCountriesRequest())
    } catch (e) {
        yield call(payload.onError)
    } finally {
        yield put(stopAction({ name: type }))
    }
}

function* facebookAuth({ type, payload }: FacebookAuthRequest) {
    yield put(startAction({ name: type }))
    const cookies = new Cookies()
    try {
        const response: LoginResponseType = yield call(AuthApi.facebookAuth, {
            token: payload.data,
            device: 'web',
        })
        cookies.set('token', response.token, { path: '/' })
        yield put(getUserRequest())
        yield call(payload.onSuccess)
        yield call(toast.success, 'Login successful')
        yield put(getCountriesRequest())
    } catch (e) {
        yield call(payload.onError)
    } finally {
        yield put(stopAction({ name: type }))
    }
}

function* register({ type, payload }: RegisterRequest) {
    yield put(startAction({ name: type }))
    const cookies = new Cookies()
    try {
        const response: RegisterResponseType = yield call(AuthApi.register, payload.data)
        cookies.set('token', response.token, { expires: moment().add(10, 'd').toDate(), path: '/' })
        yield put(registerSuccess(response))
        yield put(getUserRequest())
        yield call(payload.onSuccess)
        yield call(toast.success, 'Register successful')
    } catch (e) {
        const labels: CredentialsInvalidLabels = {
            email: '',
            nickname: '',
        }
        if (e.response?.data?.errors?.email?.includes('The email has already been taken.')) {
            labels.email = 'The email has already been taken.'
        }
        if (e.response?.data?.errors?.nickname?.includes('The nickname has already been taken.')) {
            labels.nickname = 'The nickname has already been taken.'
        }
        yield call(payload.onError, labels)
    } finally {
        yield put(stopAction({ name: type }))
    }
}

function* resetPassword({ type, payload }: ResetPasswordRequest) {
    yield put(startAction({ name: type }))
    try {
        yield call(AuthApi.resetPassword, payload.data)
        toast.success('Reset password')
    } catch (e) {
        if (!_.isEmpty(e.response?.data?.errors?.email)) {
            yield call(payload.onError, e.response?.data?.errors?.email[0])
        } else {
            yield call(payload.onError)
        }
    } finally {
        yield put(stopAction({ name: type }))
    }
}

function* getUser({ type }: GetUserRequest) {
    yield put(startAction({ name: type }))
    try {
        const response: GetUserResponseType = yield call(AuthApi.getUser)
        yield put(getUserSuccess(response.user))
        yield put(getSentRequestsRequest())
        yield put(getReceivedRequestsRequest())
    } catch (e) {
        yield put(logoutAction())
    } finally {
        yield put(stopAction({ name: type }))
    }
}

function* logout({ type }: Logout) {
    yield put(startAction({ name: type }))
    const cookies = new Cookies()
    try {
        yield cookies.remove('token', { path: '/' })
        yield put(logoutSuccess())
        yield put(resetFriends())
    } catch (e) {
    } finally {
        yield put(stopAction({ name: type }))
    }
}

function* createDeviceToken({ type, payload }: CreateDeviceTokenRequest) {
    yield put(startAction({ name: type }))
    try {
        const response = yield call(AuthApi.createDeviceToken, payload)
        yield put(createDeviceTokenSuccess(response.device_token.device_token))
    } catch (e) {
    } finally {
        yield put(stopAction({ name: type }))
    }
}

function* deleteDeviceToken({ type, payload }: DeleteDeviceTokenRequest) {
    yield put(startAction({ name: type }))
    try {
        yield call(AuthApi.deleteDeviceToken, payload)
        yield put(deleteDeviceTokenSuccess())
    } catch (e) {
    } finally {
        yield put(stopAction({ name: type }))
    }
}

export default function* authSaga() {
    return yield all([
        takeLatest(AUTH_INIT, authInit),
        takeLatest(LOGIN_REQUEST, login),
        takeLatest(REGISTER_REQUEST, register),
        takeLatest(RESET_PASSWORD_REQUEST, resetPassword),
        takeLatest(FACEBOOK_AUTH_REQUEST, facebookAuth),
        takeLatest(GOOGLE_AUTH_REQUEST, googleAuth),
        takeLatest(GET_USER_REQUEST, getUser),
        takeLatest(LOGOUT, logout),
        takeLatest(CREATE_DEVICE_TOKEN_REQUEST, createDeviceToken),
        takeLatest(DELETE_DEVICE_TOKEN_REQUEST, deleteDeviceToken),
    ])
}
