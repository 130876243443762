import { RootState } from '../rootReducer'
import { createSelector } from 'reselect'

const friendsSelector = (state: RootState) => state.friends

export const myFriendsSelector = createSelector(friendsSelector, (state) => state.my)

export const recommendedFriendsSelector = createSelector(friendsSelector, (state) => state.recommended)

export const selectedUserSelector = createSelector(friendsSelector, (state) => state.selected)

export const pendingSentSelector = createSelector(friendsSelector, (state) => state.requests.sent)

export const pendingReceivedSelector = createSelector(friendsSelector, (state) => state.requests.received)

export const searchUsersSelector = createSelector(friendsSelector, (state) => state.search)
