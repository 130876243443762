import { Country } from '../../entities/Country'
import { Action } from 'redux'

export const GET_COUNTRIES_REQUEST = '@@countries/GET_COUNTRIES_REQUEST'
export const GET_COUNTRIES_SUCCESS = '@@countries/GET_COUNTRIES_SUCCESS'

export interface CountriesState {
    data: Country[]
}

export interface GetCountriesRequest extends Action {
    type: typeof GET_COUNTRIES_REQUEST
}

export interface GetCountriesSuccess extends Action {
    type: typeof GET_COUNTRIES_SUCCESS
    payload: Country[]
}

export type CountriesActions = GetCountriesRequest | GetCountriesSuccess
