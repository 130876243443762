import React, { useEffect } from 'react'
import { useActions } from '../../utils/hooks/useActions'
import { getPrivacyRequest } from '../../store/pages/privacy.actions'
import { useSelector } from 'react-redux'
import { loadingActionSelector } from '../../store/loader/loader.selectors'
import { GET_PRIVACY_REQUEST } from '../../store/pages/privacy.types'
import { privacySelector } from '../../store/pages/privacy.selectors'
import styles from './styles.module.sass'
import PerfectScrollbar from 'react-perfect-scrollbar'

const Privacy: React.FC = () => {
    const [getPrivacyAction] = useActions([getPrivacyRequest])
    const privacy = useSelector(privacySelector)
    const loading = useSelector(loadingActionSelector)([GET_PRIVACY_REQUEST])

    useEffect(() => {
        getPrivacyAction()
    }, [getPrivacyAction])

    return (
        <PerfectScrollbar>
            <div className={styles.container}>
                <span className={styles.title}>Privacy policy</span>
                {loading ? <div>Loading...</div> : <div dangerouslySetInnerHTML={{ __html: privacy }} />}
            </div>
        </PerfectScrollbar>
    )
}

export default Privacy
