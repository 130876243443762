import React from 'react'
import styles from './styles.module.sass'
import * as Assets from './assets'
import { PageType } from '../../../pages/login'
import ClipLoader from 'react-spinners/ClipLoader'
import 'react-toastify/dist/ReactToastify.css'

interface AuthConfirmButtonProps {
    action: () => void
    type: PageType
    loading: boolean
}

const AuthConfirmButton: React.FC<AuthConfirmButtonProps> = ({ type, loading, action }) => {
    const text = () => {
        switch (type) {
            case PageType.Login:
                return 'Sign in'
            case PageType.SignUp:
                return 'Sign up'
            case PageType.ForgotPassword:
                return 'Reset password'
        }
    }
    return loading ? (
        <div className={'width-f flex-j-c mt-s'}>
            <ClipLoader color={'red'} loading={loading} size={53} />
        </div>
    ) : (
        <button className={styles.container} type={'button'} onClick={action}>
            <span className={styles.buttonText}>{text()}</span>
            <img src={Assets.Foot} alt="Foot img" className={styles.buttonImg} />
        </button>
    )
}

export default AuthConfirmButton
