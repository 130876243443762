import { LoaderActions, LoaderState, START_ACTION, STOP_ACTION } from './loader.types'
import produce from 'immer'
import { Reducer } from 'redux'
import _ from 'lodash'

const initialState: LoaderState = {
    actions: [],
}

const LoaderReducer: Reducer<LoaderState, LoaderActions> = (state = initialState, action: LoaderActions) =>
    produce(state, (draft) => {
        switch (action.type) {
            case START_ACTION:
                draft.actions.push(action.payload)
                break
            case STOP_ACTION:
                draft.actions = draft.actions.filter((a) => {
                    if (a.name !== action.payload.name) {
                        return true
                    } else {
                        return !_.isEqual(a.params, action.payload.params)
                    }
                })
                break
        }
    })

export default LoaderReducer
