import React, { useEffect } from 'react'
import styles from './styles.module.sass'
import { Link, useLocation } from 'react-router-dom'
import links from '../../../utils/links'
import TermsAndConditions from '../../components/TermsAndConditions'
import DownloadAppBar from '../../components/DownloadAppBar'
import * as Assets from './assets'
import AuthProvider from '../../contexts/authContext'
import { useActions } from '../../../utils/hooks/useActions'
import { getPopularFeedRequest } from '../../../store/feed/feed.actions'
import { useSelector } from 'react-redux'
import { popularFeedSelector } from '../../../store/feed/feed.selectors'
import { loadingActionSelector } from '../../../store/loader/loader.selectors'
import { GET_POPULAR_FEED_REQUEST } from '../../../store/feed/feed.types'
import Avatar from 'react-avatar'
import { renderUserName } from '../../../utils/common'
import PopularPhotoSkeleton from '../../loaders/PopularPhotoSkeleton'

const AuthLayout: React.FC = ({ children }) => {
    const { pathname: path } = useLocation()
    const [getPopularPhotoAction] = useActions([getPopularFeedRequest])
    const loading = useSelector(loadingActionSelector)([GET_POPULAR_FEED_REQUEST])

    const photo = useSelector(popularFeedSelector)

    const isPathMatch = (url: string) => path === url

    useEffect(() => {
        getPopularPhotoAction()
    }, [getPopularPhotoAction])

    return (
        <AuthProvider>
            <div className={styles.container}>
                <div className={`${styles.containerPart} ${styles.leftPart}`}>
                    <div className={styles.topLegs}>
                        <img src={Assets.TopLegs} alt="" width={190} className={styles.topLegs} />
                    </div>
                    <div className={'ta-c'}>
                        <img src={Assets.Title} alt="Title" width={311} className={'mb-2'} />
                        <div className={styles.postContainer}>
                            {loading ? (
                                <PopularPhotoSkeleton />
                            ) : (
                                photo && (
                                    <>
                                        <img src={photo?.media['480']} alt={'Popular pic'} className={styles.image} />
                                        <div className={styles.authorBox}>
                                            <div className={'flex-a-c'}>
                                                <Avatar
                                                    className={styles.avatar}
                                                    src={photo?.user?.avatar}
                                                    size={'50'}
                                                    name={renderUserName(photo!.user)}
                                                />
                                                <span className={styles.nickname}>{photo?.user?.nickname}</span>
                                            </div>
                                            <div className={'flex-a-c'}>
                                                <div className={styles.likeBox}>
                                                    <svg width="39" height="33" viewBox="0 0 39 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M35.074 2.77185C37.3866 4.69582 38.6166 7.1489 38.7642 10.131C38.9118 13.1132 37.9278 15.6384 35.8121 17.7067L21.5679 32.1365C21.0758 32.6175 20.51 32.858 19.8704 32.858C19.2308 32.858 18.6649 32.6175 18.1729 32.1365L3.92876 17.7067C1.81304 15.6384 0.82899 13.1132 0.976598 10.131C1.12421 7.1489 2.35427 4.69582 4.66679 2.77185C6.6841 1.08837 8.99665 0.366883 11.6044 0.60738C14.2613 0.799777 16.5246 1.83392 18.3943 3.70979L19.8704 5.15277L21.3465 3.70979C23.2162 1.83392 25.4549 0.799777 28.0626 0.60738C30.7196 0.366883 33.0567 1.08837 35.074 2.77185Z"
                                                            fill="#F70402"
                                                        />
                                                    </svg>
                                                </div>
                                                <span className={styles.likeCount}>{photo.likes.length}</span>
                                            </div>
                                        </div>
                                    </>
                                )
                            )}
                        </div>
                    </div>
                    <div className={styles.bottomLegs}>
                        <img src={Assets.BottomLegs} alt="" width={190} />
                    </div>
                </div>
                <div className={`${styles.containerPart} ${styles.rightPart} flex-centred`}>
                    <div className={`${styles.formContainer} flex-d-c flex-a-c`}>
                        <img className={styles.logo} src={Assets.Logo} alt="Logo" />
                        <div className={'width-f flex-j-c flex-d-c flex-a-c'}>
                            {!isPathMatch(links.forgotPassword) && (
                                <div className={`width-h flex-j-a`}>
                                    <Link
                                        style={{
                                            textAlign: 'right',
                                        }}
                                        to={links.register}
                                        className={`${styles.linkTab} ${styles.linkTitle} ${isPathMatch(links.register) && styles.linkTabActive}`}
                                    >
                                        Sign up
                                    </Link>
                                    <span datatype={'active'} className={`${styles.linkTab} ml-8 mr-8`}>
                                        |
                                    </span>
                                    <Link
                                        style={{
                                            textAlign: 'left',
                                        }}
                                        to={links.login}
                                        className={`${styles.linkTab} ${styles.linkTitle} ${isPathMatch(links.login) && styles.linkTabActive}`}
                                    >
                                        Login
                                    </Link>
                                </div>
                            )}
                            {children}
                            <TermsAndConditions />
                        </div>
                    </div>
                    <div className={styles.marketsBar}>
                        <DownloadAppBar />
                    </div>
                </div>
            </div>
        </AuthProvider>
    )
}

export default AuthLayout
