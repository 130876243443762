import { GET_PRIVACY_SUCCESS, GET_TERMS_SUCCESS, PrivacyActions, PrivacyState } from './privacy.types'
import { Reducer } from 'redux'
import produce from 'immer'

const initialState: PrivacyState = {
    privacy: '',
    terms: '',
}

const PrivacyReducer: Reducer<PrivacyState, PrivacyActions> = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case GET_PRIVACY_SUCCESS:
                draft.privacy = action.payload
                break
            case GET_TERMS_SUCCESS:
                draft.terms = action.payload
                break
        }
    })

export default PrivacyReducer
