import { all, fork } from 'redux-saga/effects'
import auth from './auth/auth.sagas'
import friends from './friends/friends.sagas'
import feed from './feed/feed.sagas'
import pages from './pages/privacy.sagas'
import countries from './countries/countries.sagas'

export default function* root() {
    yield all([fork(auth), fork(friends), fork(feed), fork(pages), fork(countries)])
}
