import React, { useMemo } from 'react'
import styles from './styles.module.sass'
import { Field } from 'formik'
import _ from 'lodash'

interface AuthFormFieldProps {
    name: string
    error: string | undefined
    type: 'text' | 'email' | 'password'
    placeholder: string
    disabled: boolean
}

const AuthFormField: React.FC<AuthFormFieldProps> = ({ name, type, placeholder, error, disabled }) => {
    const isError = useMemo(() => !_.isEmpty(error), [error])

    return (
        <div className={styles.fieldContainer}>
            <Field
                className={`${styles.fieldBox} ${isError && styles.errorText}`}
                name={name}
                type={type}
                placeholder={placeholder}
                disabled={disabled}
            />
            {isError && <span className={`${styles.errorBox} ${styles.errorText} ${isError && styles.visible}`}>{`*${error}`}</span>}
        </div>
    )
}

export default AuthFormField
