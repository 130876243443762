import { ActionCreator } from 'redux'
import {
    CLEAR_FEED,
    ClearFeed,
    COMMENT_FEED_REQUEST,
    COMMENT_FEED_SUCCESS,
    CommentFeedRequest,
    CommentFeedSuccess,
    GET_FEED_ADDITIONAL_REQUEST,
    GET_FEED_ADDITIONAL_SUCCESS,
    GET_FEED_BY_ID_REQUEST,
    GET_FEED_BY_ID_SUCCESS,
    GET_FEED_REQUEST,
    GET_FEED_SUCCESS,
    GET_POPULAR_FEED_REQUEST,
    GET_POPULAR_FEED_SUCCESS,
    GetFeedAdditionalRequest,
    GetFeedAdditionalSuccess,
    GetFeedByIdRequest,
    GetFeedByIdSuccess,
    GetFeedRequest,
    GetFeedSuccess,
    GetPopularFeedRequest,
    GetPopularFeedSuccess,
    LIKE_FEED_REQUEST,
    LIKE_FEED_SUCCESS,
    LikeFeedRequest,
    LikeFeedSuccess,
    SELECT_FEED,
    SelectFeed,
    UNLIKE_FEED_REQUEST,
    UNLIKE_FEED_SUCCESS,
    UnlikeFeedRequest,
    UnlikeFeedSuccess,
} from './feed.types'
import { Feed } from '../../entities/Feed'
import { Like } from '../../entities/Like'
import { CommentFeedRequestType } from '../../entities/requests'
import { CommentFeedResponseType } from '../../entities/responses'

export const getFeedRequest: ActionCreator<GetFeedRequest> = () => ({
    type: GET_FEED_REQUEST,
})

export const getFeedSuccess: ActionCreator<GetFeedSuccess> = (payload: { data: Feed[]; totalPages: number }) => ({
    type: GET_FEED_SUCCESS,
    payload,
})

export const getFeedAdditionalRequest: ActionCreator<GetFeedAdditionalRequest> = (payload: number) => ({
    type: GET_FEED_ADDITIONAL_REQUEST,
    payload,
})

export const getFeedAdditionalSuccess: ActionCreator<GetFeedAdditionalSuccess> = (payload: Feed[]) => ({
    type: GET_FEED_ADDITIONAL_SUCCESS,
    payload,
})

export const getPopularFeedRequest: ActionCreator<GetPopularFeedRequest> = () => ({
    type: GET_POPULAR_FEED_REQUEST,
})

export const getPopularFeedSuccess: ActionCreator<GetPopularFeedSuccess> = (payload: Feed) => ({
    type: GET_POPULAR_FEED_SUCCESS,
    payload,
})

export const getFeedByIdRequest: ActionCreator<GetFeedByIdRequest> = (payload: { id: string; onError: () => void }) => ({
    type: GET_FEED_BY_ID_REQUEST,
    payload,
})

export const getFeedByIdSuccess: ActionCreator<GetFeedByIdSuccess> = (payload: Feed) => ({
    type: GET_FEED_BY_ID_SUCCESS,
    payload,
})

export const likeFeedRequest: ActionCreator<LikeFeedRequest> = (payload: { id: string; onRequestEnd: () => void }) => ({
    type: LIKE_FEED_REQUEST,
    payload,
})

export const likeFeedSuccess: ActionCreator<LikeFeedSuccess> = (payload: { id: number; data: Like[] }) => ({
    type: LIKE_FEED_SUCCESS,
    payload,
})

export const unlikeFeedRequest: ActionCreator<UnlikeFeedRequest> = (payload: { id: string; onRequestEnd: () => void }) => ({
    type: UNLIKE_FEED_REQUEST,
    payload,
})

export const unlikeFeedSuccess: ActionCreator<UnlikeFeedSuccess> = (payload: { id: number; data: Like[] }) => ({
    type: UNLIKE_FEED_SUCCESS,
    payload,
})

export const commentFeedRequest: ActionCreator<CommentFeedRequest> = (payload: CommentFeedRequestType) => ({
    type: COMMENT_FEED_REQUEST,
    payload,
})

export const commentFeedSuccess: ActionCreator<CommentFeedSuccess> = (payload: CommentFeedResponseType) => ({
    type: COMMENT_FEED_SUCCESS,
    payload,
})

export const selectFeed: ActionCreator<SelectFeed> = (payload: Feed) => ({
    type: SELECT_FEED,
    payload,
})

export const clearFeed: ActionCreator<ClearFeed> = () => ({
    type: CLEAR_FEED,
})
