import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import links from '../../../utils/links'
import useCookies from '../../../utils/hooks/useCookies'
import { useSelector } from 'react-redux'
import { loadingActionSelector } from '../../../store/loader/loader.selectors'
import { AUTH_INIT } from '../../../store/auth/auth.types'

interface PrivateRouteProps {
    // tslint:disable-next-line:no-any
    component: React.FC<any>

    // tslint:disable-next-line:no-any
    [key: string]: any
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {
    const loading = useSelector(loadingActionSelector)([AUTH_INIT])
    const { tokenExist } = useCookies()
    return <Route {...rest} render={(props) => (tokenExist ? loading ? 'Loading...' : <Component {...props} /> : <Redirect to={links.login} />)} />
}

export default PrivateRoute
