import { useHistory } from 'react-router-dom'
import { useMemo } from 'react'

export const useNavigation = () => {
    const history = useHistory()
    return useMemo(
        () => ({
            navigateTo: (url: string, state?: unknown) => {
                history.push(url, state)
            },
            back: () => {
                history.goBack()
            },
            getLocation: () => history.location,
        }),
        [history]
    )
}
