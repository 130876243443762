import HttpService from './index'
import { Country } from '../entities/Country'

class CountriesApi extends HttpService {
    getCountries = (): Promise<Country[]> => {
        return this.get(`countries/get`)
    }
}

export default new CountriesApi({})
