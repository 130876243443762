import { ActionCreator } from 'redux'
import { GET_COUNTRIES_REQUEST, GET_COUNTRIES_SUCCESS, GetCountriesRequest, GetCountriesSuccess } from './countries.types'
import { Country } from '../../entities/Country'

export const getCountriesRequest: ActionCreator<GetCountriesRequest> = () => ({
    type: GET_COUNTRIES_REQUEST,
})

export const getCountriesSuccess: ActionCreator<GetCountriesSuccess> = (payload: Country[]) => ({
    type: GET_COUNTRIES_SUCCESS,
    payload,
})
