import { User } from '../../entities/User'

export const renderUserName = (user: User, full?: boolean, len: number = 8) => {
    if (full) {
        return user
            ? `${
                  user.first_name && user.last_name
                      ? `${user.first_name} ${user.last_name}`
                      : user.nickname.length > len
                      ? `${user.nickname.slice(0, len)}...`
                      : user.nickname
              }`
            : ''
    } else {
        return user
            ? `${
                  user.first_name && user.last_name
                      ? `${user.first_name!.length > len ? `${user.first_name!.slice(0, 6)}...` : user.first_name} ${user.last_name[0]}.`
                      : user.nickname.length > len
                      ? `${user.nickname.slice(0, len)}...`
                      : user.nickname
              }`
            : ''
    }
}

export const renderProfileName = (user: User) => {
    if (user.first_name || user.last_name) {
        if (!user.last_name) {
            return user && `${user.first_name!.length > 15 ? `${user.first_name!.slice(0, 15)}...` : user.first_name}`
        } else if (!user.first_name) {
            return user && `${user.last_name!.length > 15 ? `${user.last_name!.slice(0, 15)}...` : user.last_name}`
        } else {
            return `${user.first_name!.length > 15 ? `${user.first_name!.slice(0, 15)}...` : user.first_name} ${user.last_name[0]}.`
        }
    } else {
        return user.nickname.length > 15 ? `${user.nickname.slice(0, 15)}...` : user.nickname
    }
}
