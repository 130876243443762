import { combineReducers } from 'redux'
import auth from './auth/auth.reducer'
import loader from './loader/loader.reducer'
import friends from './friends/friends.reducer'
import feed from './feed/feed.reducer'
import pages from './pages/privacy.reducer'
import countries from './countries/contries.reducer'

const rootReducer = combineReducers({ auth, loader, friends, feed, pages, countries })

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
