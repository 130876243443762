import React, { useEffect } from 'react'
import { useActions } from '../../utils/hooks/useActions'
import { getTermsRequest } from '../../store/pages/privacy.actions'
import { useSelector } from 'react-redux'
import { loadingActionSelector } from '../../store/loader/loader.selectors'
import { GET_TERMS_REQUEST } from '../../store/pages/privacy.types'
import { termsSelector } from '../../store/pages/privacy.selectors'
import styles from './styles.module.sass'
import Scrollable from 'react-perfect-scrollbar'

const Terms: React.FC = () => {
    const [getTermsAction] = useActions([getTermsRequest])
    const terms = useSelector(termsSelector)
    const loading = useSelector(loadingActionSelector)([GET_TERMS_REQUEST])

    useEffect(() => {
        getTermsAction()
    }, [getTermsAction])

    return (
        <Scrollable>
            <div className={styles.container}>
                <span className={styles.title}>Terms and Conditions</span>
                {loading ? <div>Loading...</div> : <div dangerouslySetInnerHTML={{ __html: terms }} />}
            </div>
        </Scrollable>
    )
}

export default Terms
