import * as Yup from 'yup'

export interface AuthFormFields {
    nickname?: string
    email?: string
    password?: string
}

export const SignUpSchema = Yup.object().shape({
    nickname: Yup.string()
        .required('Nickname is required')
        .matches(/^[A-Za-z0-9]+$/, 'Please do use latin characters'),
    email: Yup.string().email('The email must be a valid email address').required('Email is required').trim(),
    password: Yup.string().required('The password must be at least 8 characters').min(8, 'Password must be more than 8 symbols').trim(),
})

export const SignInSchema = Yup.object().shape({
    email: Yup.string().email('The email must be a valid email address').required('Email is required').trim(),
    password: Yup.string().required('The password must be at least 8 characters').min(8, 'Password must be more than 8 symbols').trim(),
})

export const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email('The email must be a valid email address').required('Email is required').trim(),
})
