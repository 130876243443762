import React, { useMemo } from 'react'
import styles from '../user/styles.module.sass'
import UserProfileSkeleton from '../../common/loaders/UserProfileSkeleton'
import Avatar from 'react-avatar'
import { renderProfileName, renderUserName } from '../../utils/common'
import * as Assets from '../user/assets'
import { User } from '../../entities/User'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { userSelector } from '../../store/auth/auth.selectors'
import { loadingActionSelector } from '../../store/loader/loader.selectors'
import { GET_USER_REQUEST } from '../../store/auth/auth.types'
import _ from 'lodash'
import { countriesSelector } from '../../store/countries/countries.selectors'
import { GET_COUNTRIES_REQUEST } from '../../store/countries/countries.types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import NothingFoundBlock from '../../common/components/NothingFoundBlock'

const Profile: React.FC = () => {
    const myProfile = useSelector(userSelector)
    const loading = useSelector(loadingActionSelector)([GET_USER_REQUEST])
    const countryLoading = useSelector(loadingActionSelector)([GET_COUNTRIES_REQUEST])
    const countries = useSelector(countriesSelector)

    const myCountry = useMemo(() => {
        const country = _.find(countries, { id: myProfile?.country_id })
        // @ts-ignore
        return country?.name || ''
    }, [countries, myProfile])

    return (
        <PerfectScrollbar>
            <div className={styles.publicationsContainer}>
                {loading || countryLoading ? (
                    <UserProfileSkeleton pageType={'profile'} />
                ) : (
                    <>
                        <div className={`${styles.leftBar} shadow`}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <div className={`${styles.userInfo} flex-d-r flex-a-c`} style={{ margin: '0 auto' }}>
                                    <Avatar
                                        src={myProfile?.avatar}
                                        name={renderUserName(myProfile!)}
                                        size={'78'}
                                        round={true}
                                        className={`${styles.avatar} mr-4`}
                                    />
                                    <div className={`flex-d-c flex-j-c ${styles.userNameWrap}`}>
                                        <span className={styles.userName}>
                                            {myProfile!.nickname.length > 14 ? `${myProfile!.nickname.slice(0, 14)}...` : myProfile!.nickname}
                                        </span>
                                        <div className={'flex-d-c'}>
                                            <span className={styles.subText}>{renderProfileName(myProfile!)}</span>
                                            <span className={styles.subText}>{`${myProfile?.city || ''}${myCountry && myProfile?.city ? `, ` : ''}${
                                                myCountry ? `${myCountry}` : ''
                                            }`}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${styles.userInfo} height-f flex-a-c mt-9 mb-3`}>
                                    <div className={`flex-d-r flex-a-c flex-j-s ${styles.friendIconBox}`}>
                                        <img className={`mr-2 ${styles.friendsIcon}`} src={Assets.Friend} width={26} alt="Friend ico" />
                                        <span>Friends</span>
                                    </div>
                                    <span className={styles.friendsBadge}>{`${myProfile?.friends.length}`}</span>
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.mainBox} shadow`}>
                            {_.isEmpty(myProfile?.friends) ? (
                                <NothingFoundBlock label={'My friends'} text={'Start searching for your friends!'} />
                            ) : (
                                <>
                                    <div className={`${styles.friendsBox} width-f`}>
                                        <span className={styles.title}>My friends</span>
                                        <div className={styles.usersContainer} style={{ marginTop: '30px' }}>
                                            {myProfile?.friends.map((friend: User) => (
                                                <div className={`${styles.userItem}`} key={friend.id}>
                                                    <Link
                                                        className={`${styles.link} flex-centred flex-d-c`}
                                                        to={`/user/${friend.id}`}
                                                        key={friend.id}
                                                    >
                                                        <Avatar
                                                            className={styles.avatar}
                                                            src={friend.avatar}
                                                            name={renderUserName(friend)}
                                                            size={'78'}
                                                        />
                                                        <span className={styles.name}>{renderUserName(friend)}</span>
                                                    </Link>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                )}
            </div>
        </PerfectScrollbar>
    )
}

export default Profile
