import { RootState } from '../rootReducer'
import { createSelector } from 'reselect'

export const feedSelector = (state: RootState) => state.feed

export const feedDataSelector = createSelector(feedSelector, (state) => state.data.feed)

export const feedPaginationSelector = createSelector(feedSelector, (state) => state.data.pagination)

export const feedLastUpdateSelector = createSelector(feedSelector, (state) => state.data.lastUpdated)

export const feedSelectedSelector = createSelector(feedSelector, (state) => state.selected)

export const popularFeedSelector = createSelector(feedSelector, (state) => state.popular)
