import { ActionCreator } from 'redux'
import {
    ACCEPT_FRIENDSHIP_REQUEST,
    ACCEPT_FRIENDSHIP_SUCCESS,
    AcceptFriendshipRequest,
    AcceptFriendshipSuccess,
    ADD_USER_REQUEST,
    ADD_USER_SUCCESS,
    AddUserRequest,
    AddUserSuccess,
    CANCEL_SENT_REQUEST,
    CANCEL_SENT_SUCCESS,
    CancelSentRequest,
    CancelSentSuccess,
    CLEAR_SELECTED,
    ClearSelected,
    GET_FRIENDS_REQUEST,
    GET_FRIENDS_SUCCESS,
    GET_RECEIVED_REQUESTS_REQUEST,
    GET_RECEIVED_REQUESTS_SUCCESS,
    GET_RECOMMENDED_REQUEST,
    GET_RECOMMENDED_SUCCESS,
    GET_SENT_REQUESTS_REQUEST,
    GET_SENT_REQUESTS_SUCCESS,
    GET_USER_BY_ID_REQUEST,
    GET_USER_BY_ID_SUCCESS,
    GetFriendsRequest,
    GetFriendsSuccess,
    GetReceivedRequestsRequest,
    GetReceivedRequestsSuccess,
    GetRecommendedRequest,
    GetRecommendedSuccess,
    GetSentRequestsRequest,
    GetSentRequestsSuccess,
    GetUserByIdRequest,
    GetUserByIdSuccess,
    RESET_FRIENDS,
    ResetFriends,
    SEARCH_USER_REQUEST,
    SEARCH_USER_SUCCESS,
    SearchUserRequest,
    SearchUserSuccess,
} from './friends.types'
import { User } from '../../entities/User'
import { Friendship } from '../../entities/Friendship'

export const getFriendsRequest: ActionCreator<GetFriendsRequest> = (payload: { onSuccess: () => void } = { onSuccess: () => {} }) => ({
    type: GET_FRIENDS_REQUEST,
    payload,
})

export const getFriendsSuccess: ActionCreator<GetFriendsSuccess> = (payload: User[]) => ({
    type: GET_FRIENDS_SUCCESS,
    payload,
})

export const getRecommendedRequest: ActionCreator<GetRecommendedRequest> = () => ({
    type: GET_RECOMMENDED_REQUEST,
})

export const getRecommendedSuccess: ActionCreator<GetRecommendedSuccess> = (payload: User[]) => ({
    type: GET_RECOMMENDED_SUCCESS,
    payload,
})

export const getUserByIdRequest: ActionCreator<GetUserByIdRequest> = (payload: { id: string; onError: () => void }) => ({
    type: GET_USER_BY_ID_REQUEST,
    payload,
})

export const getUserByIdSuccess: ActionCreator<GetUserByIdSuccess> = (payload: { user: User; myId: string }) => ({
    type: GET_USER_BY_ID_SUCCESS,
    payload,
})

export const addUserRequest: ActionCreator<AddUserRequest> = (payload: { id: number; onRequestEnd: () => void }) => ({
    type: ADD_USER_REQUEST,
    payload,
})

export const addUserSuccess: ActionCreator<AddUserSuccess> = (payload: Friendship) => ({
    type: ADD_USER_SUCCESS,
    payload,
})

export const acceptFriendshipRequest: ActionCreator<AcceptFriendshipRequest> = (payload: { user: User; onSuccess: () => void }) => ({
    type: ACCEPT_FRIENDSHIP_REQUEST,
    payload,
})

export const acceptFriendshipSuccess: ActionCreator<AcceptFriendshipSuccess> = (payload: User) => ({
    type: ACCEPT_FRIENDSHIP_SUCCESS,
    payload,
})

export const cancelSentRequest: ActionCreator<CancelSentRequest> = (payload: number) => ({
    type: CANCEL_SENT_REQUEST,
    payload,
})

export const cancelSentSuccess: ActionCreator<CancelSentSuccess> = (payload: number) => ({
    type: CANCEL_SENT_SUCCESS,
    payload,
})

export const getSentRequestsRequest: ActionCreator<GetSentRequestsRequest> = () => ({
    type: GET_SENT_REQUESTS_REQUEST,
})

export const getSentRequestsSuccess: ActionCreator<GetSentRequestsSuccess> = (payload: User[]) => ({
    type: GET_SENT_REQUESTS_SUCCESS,
    payload,
})

export const getReceivedRequestsRequest: ActionCreator<GetReceivedRequestsRequest> = () => ({
    type: GET_RECEIVED_REQUESTS_REQUEST,
})

export const getReceivedRequestsSuccess: ActionCreator<GetReceivedRequestsSuccess> = (payload: User[]) => ({
    type: GET_RECEIVED_REQUESTS_SUCCESS,
    payload,
})

export const searchUserRequest: ActionCreator<SearchUserRequest> = (payload: { keyword: string; onSuccess: () => void }) => ({
    type: SEARCH_USER_REQUEST,
    payload,
})

export const searchUserSuccess: ActionCreator<SearchUserSuccess> = (payload: User[]) => ({
    type: SEARCH_USER_SUCCESS,
    payload,
})

export const clearSelected: ActionCreator<ClearSelected> = () => ({
    type: CLEAR_SELECTED,
})

export const resetFriends: ActionCreator<ResetFriends> = () => ({
    type: RESET_FRIENDS,
})
