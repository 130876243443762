import React from 'react'
import styles from './styles.module.sass'
import Avatar from 'react-avatar'
import { Comment as CommentType } from '../../../entities/Comment'
import { renderUserName } from '../../../utils/common'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import _ from 'lodash'
import { Link } from 'react-router-dom'

TimeAgo.addDefaultLocale(en)

interface CommentProps {
    item: CommentType
    replies: CommentType[]
    onReply: () => void
}

const Comment: React.FC<CommentProps> = ({ item, replies, onReply }) => {
    const timeAgo = new TimeAgo('en-US')
    return (
        <div className={styles.container} style={{ marginTop: _.isEmpty(replies) ? '30px' : '25px' }}>
            <div style={{ position: 'relative', width: '100%' }}>
                <div className={'width-f flex-d-r'}>
                    <Avatar
                        // unstyled={!_.isEmpty(item.author.avatar)}
                        src={item.author.avatar}
                        name={renderUserName(item.author)}
                        className={`${styles.avatar}`}
                    />
                    <div className={`flex-d-c flex-j-a ${styles.commentBox}`}>
                        <Link to={`/user/${item.author.id}`} className={styles.link}>
                            <span className={styles.userName}>{renderUserName(item.author)}</span>
                        </Link>
                        <div className={styles.commentBody}>{item.body}</div>
                        <span className={styles.reply} onClick={onReply}>
                            Reply
                        </span>
                    </div>
                </div>
                <span className={styles.timeAgo}>{timeAgo.format(new Date(item.created_at))}</span>
            </div>
            <div className={styles.replies}>
                {replies.map((reply, index) => (
                    <div key={reply.id} className={`flex-d-c flex-j-a ${styles.commentBox}`} style={{ marginTop: index === 0 ? 25 : 8 }}>
                        <Link to={`/user/${reply.author.id}`} className={styles.link}>
                            <span className={styles.userNameReply}>{renderUserName(reply.author)}</span>
                        </Link>
                        <span className={styles.commentBody}>{reply.body}</span>
                        <span className={styles.timeAgoReply}>{timeAgo.format(new Date(reply.created_at))}</span>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Comment
