import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { myFriendsSelector, pendingReceivedSelector, searchUsersSelector } from '../../store/friends/friends.selectors'
import { loadingActionSelector } from '../../store/loader/loader.selectors'
import { SEARCH_USER_REQUEST } from '../../store/friends/friends.types'
import styles from './styles.module.sass'
import RecommendedFriends from '../../common/components/RecommendedFriends'
import { User } from '../../entities/User'
import SearchItem from './components/SearchItem'
import SearchItemSkeleton from '../../common/loaders/SearchItemSkeleton'
import { useActions } from '../../utils/hooks/useActions'
import { acceptFriendshipRequest, addUserRequest, getFriendsRequest } from '../../store/friends/friends.actions'
import { userSelector } from '../../store/auth/auth.selectors'
import Friends from '../../common/components/Friends'
import _ from 'lodash'
import PerfectScrollbar from 'react-perfect-scrollbar'
import NothingFoundBlock from '../../common/components/NothingFoundBlock'

const SearchPage: React.FC = () => {
    const myProfile = useSelector(userSelector)
    const result = useSelector(searchUsersSelector)
    const friends = useSelector(myFriendsSelector)
    const receivedRequests = useSelector(pendingReceivedSelector)
    const searchLoading = useSelector(loadingActionSelector)([SEARCH_USER_REQUEST])
    const [addUserAction, getFriendsAction, acceptRequestAction] = useActions([addUserRequest, getFriendsRequest, acceptFriendshipRequest])

    useEffect(() => {
        if (myProfile) {
            getFriendsAction()
        }
    }, [getFriendsAction, myProfile])

    const renderResult = useMemo(
        () =>
            _.isEmpty(result) ? (
                <NothingFoundBlock label={"Please type your friend's name"} text={'No users found for this query :('} />
            ) : (
                result.map((user: User, index) => (
                    <SearchItem
                        key={user.id}
                        item={user}
                        isLastItem={index < result.length - 1}
                        isFriend={!!_.find(friends, { id: user.id })}
                        onAdd={(callback) => {
                            if (!!_.find(receivedRequests, { id: user.id })) {
                                acceptRequestAction({
                                    user,
                                    onSuccess: callback,
                                })
                            } else {
                                addUserAction({ id: user.id, onRequestEnd: callback })
                            }
                        }}
                        receivedRequest={!!_.find(receivedRequests, { id: user.id })}
                    />
                ))
            ),
        [result]
    )

    return (
        <PerfectScrollbar>
            <div className={`${styles.publicationsContainer}`}>
                <div className={styles.leftBar}>
                    <RecommendedFriends />
                </div>
                <div className={`${styles.centerBox} shadow`}>
                    {searchLoading ? Array.from({ length: 7 }, (a, index) => <SearchItemSkeleton key={index} index={index} />) : renderResult}
                </div>
                <div className={styles.rightBar}>
                    <Friends />
                </div>
            </div>
        </PerfectScrollbar>
    )
}

export default SearchPage
