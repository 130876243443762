import HttpService from './index'
import config from './config'

class PagesApi extends HttpService {
    getPrivacy = () => {
        return this.get('privacy-policy')
    }

    getTerms = () => {
        return this.get('terms')
    }
}

export default new PagesApi({ apiURL: config.PAGE_URL })
