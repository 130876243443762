import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

interface RecommendedFriendSkeletonProps {
    isLastItem?: boolean
}

const RecommendedFriendSkeleton: React.FC<RecommendedFriendSkeletonProps> = ({ isLastItem }) => (
    <div style={{ marginBottom: !isLastItem ? '25px' : '0' }}>
        <SkeletonTheme color="#E8E8F0" highlightColor="#FFFFFF">
            <div className={'flex-j-b flex-a-c'}>
                <Skeleton circle={true} height={50} width={50} className={'mr-3'} style={{ lineHeight: 'unset' }} />
                <div className={'flex-d-c width-f'}>
                    <Skeleton height={7} width={'90%'} style={{ borderRadius: 30, lineHeight: '0px' }} />
                    <Skeleton height={5} width={'75%'} style={{ borderRadius: 30, lineHeight: '4px' }} />
                </div>
            </div>
        </SkeletonTheme>
    </div>
)

export default RecommendedFriendSkeleton
