import { ActionCreator } from 'redux'
import {
    GET_PRIVACY_REQUEST,
    GET_PRIVACY_SUCCESS,
    GET_TERMS_REQUEST,
    GET_TERMS_SUCCESS,
    GetPrivacyRequest,
    GetPrivacySuccess,
    GetTermsRequest,
    GetTermsSuccess,
} from './privacy.types'

export const getPrivacyRequest: ActionCreator<GetPrivacyRequest> = () => ({
    type: GET_PRIVACY_REQUEST,
})

export const getPrivacySuccess: ActionCreator<GetPrivacySuccess> = (payload: string) => ({
    type: GET_PRIVACY_SUCCESS,
    payload,
})

export const getTermsRequest: ActionCreator<GetTermsRequest> = () => ({
    type: GET_TERMS_REQUEST,
})

export const getTermsSuccess: ActionCreator<GetTermsSuccess> = (payload: string) => ({
    type: GET_TERMS_SUCCESS,
    payload,
})
