import { ActionCreator } from 'redux'
import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    REGISTER_REQUEST,
    REGISTER_SUCCESS,
    RESET_PASSWORD_REQUEST,
    GOOGLE_AUTH_REQUEST,
    LoginRequest,
    LoginSuccess,
    RegisterRequest,
    RegisterSuccess,
    ResetPasswordRequest,
    GoogleAuthRequest,
    AuthInit,
    AUTH_INIT,
    GetUserRequest,
    GET_USER_REQUEST,
    GetUserSuccess,
    GET_USER_SUCCESS,
    Logout,
    LOGOUT,
    LogoutSuccess,
    LOGOUT_SUCCESS,
    FacebookAuthRequest,
    FACEBOOK_AUTH_REQUEST,
    CreateDeviceTokenRequest,
    CREATE_DEVICE_TOKEN_REQUEST,
    DeleteDeviceTokenRequest,
    DELETE_DEVICE_TOKEN_REQUEST,
    CreateDeviceTokenSuccess,
    CREATE_DEVICE_TOKEN_SUCCESS,
    DeleteDeviceTokenSuccess,
    DELETE_DEVICE_TOKEN_SUCCESS,
} from './auth.types'
import { LoginRequestType, RegisterRequestType, ResetPasswordRequestType } from '../../entities/requests'
import { User } from '../../entities/User'
import { RegisterResponseType } from '../../entities/responses'
import { GoogleLoginResponse } from 'react-google-login'
import { CredentialsInvalidLabels } from '../../pages/register'

export const authInit: ActionCreator<AuthInit> = (payload: { onSuccess: () => void; onTooManyRequests: () => void }) => ({
    type: AUTH_INIT,
    payload,
})

export const loginRequest: ActionCreator<LoginRequest> = (payload: { data: LoginRequestType; onSuccess: () => void; onError: () => void }) => ({
    type: LOGIN_REQUEST,
    payload,
})

export const googleAuthRequest: ActionCreator<GoogleAuthRequest> = (payload: {
    data: GoogleLoginResponse
    onSuccess: () => void
    onError: () => void
}) => ({
    type: GOOGLE_AUTH_REQUEST,
    payload,
})

export const facebookAuthRequest: ActionCreator<FacebookAuthRequest> = (payload: { data: string; onSuccess: () => void; onError: () => void }) => ({
    type: FACEBOOK_AUTH_REQUEST,
    payload,
})

export const loginSuccess: ActionCreator<LoginSuccess> = (payload: { token: string; user: User }) => ({
    type: LOGIN_SUCCESS,
    payload,
})

export const registerRequest: ActionCreator<RegisterRequest> = (payload: {
    data: RegisterRequestType
    onSuccess: () => void
    onError: (label?: CredentialsInvalidLabels) => void
}) => ({
    type: REGISTER_REQUEST,
    payload,
})

export const registerSuccess: ActionCreator<RegisterSuccess> = (payload: RegisterResponseType) => ({
    type: REGISTER_SUCCESS,
    payload,
})

export const resetPasswordRequest: ActionCreator<ResetPasswordRequest> = (payload: {
    data: ResetPasswordRequestType
    onError: (label?: string) => void
}) => ({
    type: RESET_PASSWORD_REQUEST,
    payload,
})

export const getUserRequest: ActionCreator<GetUserRequest> = () => ({
    type: GET_USER_REQUEST,
})

export const getUserSuccess: ActionCreator<GetUserSuccess> = (payload: User) => ({
    type: GET_USER_SUCCESS,
    payload,
})

export const createDeviceTokenRequest: ActionCreator<CreateDeviceTokenRequest> = (payload: string) => ({
    type: CREATE_DEVICE_TOKEN_REQUEST,
    payload,
})

export const createDeviceTokenSuccess: ActionCreator<CreateDeviceTokenSuccess> = (payload: string) => ({
    type: CREATE_DEVICE_TOKEN_SUCCESS,
    payload,
})

export const deleteDeviceTokenRequest: ActionCreator<DeleteDeviceTokenRequest> = (payload: string) => ({
    type: DELETE_DEVICE_TOKEN_REQUEST,
    payload,
})

export const deleteDeviceTokenSuccess: ActionCreator<DeleteDeviceTokenSuccess> = () => ({
    type: DELETE_DEVICE_TOKEN_SUCCESS,
})

export const logout: ActionCreator<Logout> = () => ({
    type: LOGOUT,
})

export const logoutSuccess: ActionCreator<LogoutSuccess> = () => ({
    type: LOGOUT_SUCCESS,
})
