export const requestNotificationPermission = () => {
    if (!('Notification' in window)) {
        alert('This browser does not support desktop notification')
    } else if (Notification.permission === 'granted') {
    } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
            }
        })
    }
}
