import React, { useCallback, useState } from 'react'
import { User } from '../../../../entities/User'
import Avatar from 'react-avatar'
import { renderUserName } from '../../../../utils/common'
import styles from './styles.module.sass'
import { Link } from 'react-router-dom'
import useIsInSentPending from '../../../../utils/hooks/useIsInSentPending'
import useIsMyFriend from '../../../../utils/hooks/useIsMyFriend'

interface SearchItemProps {
    item: User
    onAdd: (callback: () => void) => void
    isFriend: boolean
    receivedRequest: boolean
    isLastItem: boolean
}

const SearchItem: React.FC<SearchItemProps> = ({ item, onAdd, isFriend, receivedRequest, isLastItem }) => {
    const isSentPending = useIsInSentPending(item.id)
    const isMyFriend = useIsMyFriend(Number(item.id))
    const [disabled, setDisabled] = useState<boolean>(false)
    const [added, setAdded] = useState<boolean>(false)

    const handleToggleDisabled = useCallback(() => {
        setDisabled((prevState) => !prevState)
    }, [setDisabled])

    return (
        <div className={'width-f flex-j-b flex-a-c' + (isLastItem ? ' mb-7' : '')}>
            <div className={'flex-a-c'}>
                <Avatar size={'50'} className={`${styles.avatar} mr-7`} src={item.avatar} name={renderUserName(item)} />
                <Link to={`/user/${item.id}`} className={styles.name}>
                    {renderUserName(item)}
                </Link>
            </div>
            {isMyFriend ? (
                <button className={styles.button} style={{ padding: '6px', backgroundColor: '#15593E' }} disabled>
                    <span>Accepted</span>
                </button>
            ) : added || isSentPending || isFriend ? (
                <button className={`${styles.button} flex-centred`} style={{ padding: '6px', backgroundColor: '#15593E' }} disabled>
                    Sent
                </button>
            ) : (
                <button
                    className={styles.button}
                    onClick={() => {
                        handleToggleDisabled()
                        onAdd(
                            receivedRequest
                                ? () => {
                                      handleToggleDisabled()
                                      setAdded(true)
                                  }
                                : handleToggleDisabled
                        )
                    }}
                    style={{ padding: '6px' }}
                    disabled={disabled}
                >
                    Add
                </button>
            )}
        </div>
    )
}

export default SearchItem
