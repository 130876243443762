import HttpService from './index'
import { User } from '../entities/User'
import { AddUserResponseType, GetRecommendedFriendsResponseType } from '../entities/responses'

class FriendsApi extends HttpService {
    getFriends = (userId: string): Promise<User[]> => {
        return this.post(`friend/index/${userId}`)
    }

    getRecommended = (): Promise<GetRecommendedFriendsResponseType> => {
        return this.get('user/recommended-users')
        // return this.post('search', { keyword: '67' })
    }

    getUserById = (userId: string) => {
        return this.get(`users/${userId}`)
    }

    addUser = (userId: number): Promise<AddUserResponseType> => {
        return this.post(`friend/add/${userId}`)
    }

    acceptFriendship = (userId: number) => {
        return this.post(`friend/accept/${userId}`)
    }

    cancelSentRequest = (userId: number) => {
        return this.post(`friend/cancel/${userId}`)
    }

    getPendingSent = () => {
        return this.post('friend/pending-sent')
    }

    getPendingReceived = () => {
        return this.post('friend/pending-received')
    }

    search = (keyword: string) => {
        return this.post('search', { keyword })
    }
}

export default new FriendsApi({})
