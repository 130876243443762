import React, { useEffect } from 'react'
import styles from './styles.module.sass'
import Skeleton from 'react-loading-skeleton'
import MyFriendsSkeleton from '../MyFriendsSkeleton'

interface UserProfileSkeletonProps {
    pageType: string
    scrollToTop?: () => void
}

const UserProfileSkeleton: React.FC<UserProfileSkeletonProps> = ({ pageType, scrollToTop }) => {
    useEffect(() => {
        if (scrollToTop) {
            scrollToTop()
        }
    }, [scrollToTop])

    return (
        <>
            <div className={`${styles.leftBar} flex-d-c flex-a-c shadow`}>
                <div className={'flex-d-c flex-a-s width-f mb-3'} style={{ minHeight: 151 }}>
                    <div className={'flex-a-c flex-j-s width-f'}>
                        <Skeleton circle={true} width={80} height={80} style={{ lineHeight: 'unset' }} />
                        <div className={'flex-d-c ml-4'}>
                            <Skeleton height={7} width={130} style={{ borderRadius: 30, lineHeight: '10px' }} />
                            <Skeleton height={5} width={90} style={{ borderRadius: 30, lineHeight: '0px' }} />
                        </div>
                    </div>
                    <Skeleton height={7} width={200} className={'mt-10 ml-3'} />
                </div>
            </div>
            <div className={`${styles.centerBox} shadow`}>
                <Skeleton height={14} width={150} style={{ lineHeight: '0px', marginLeft: '5px' }} />
                <div className={styles.skeletonWrapper}>
                    {Array.from({ length: 12 }, (_, index) => (
                        <div className={'flex-d-c flex-a-c flex-j-c width-q'} key={index}>
                            <Skeleton circle={true} width={80} height={80} className={'mb-3'} />
                            <Skeleton height={6} width={80} />
                            <Skeleton height={6} width={80} />
                        </div>
                    ))}
                </div>
            </div>
            {pageType === 'user' ? (
                <div className={`${styles.rightBar} shadow`}>
                    <span className={styles.title}>My friends</span>
                    <div className="d-flex flex-a-c mt-4">
                        <MyFriendsSkeleton />
                    </div>
                </div>
            ) : null}
        </>
        // </SkeletonTheme>
    )
}

export default UserProfileSkeleton
