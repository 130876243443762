import {
    CLEAR_FEED,
    COMMENT_FEED_SUCCESS,
    FeedActions,
    FeedState,
    GET_FEED_ADDITIONAL_SUCCESS,
    GET_FEED_BY_ID_SUCCESS,
    GET_FEED_SUCCESS,
    GET_POPULAR_FEED_SUCCESS,
    LIKE_FEED_SUCCESS,
    SELECT_FEED,
    UNLIKE_FEED_SUCCESS,
} from './feed.types'
import { Reducer } from 'redux'
import produce from 'immer'
import { Feed } from '../../entities/Feed'
import moment from 'moment'

const initialState: FeedState = {
    data: {
        feed: [],
        pagination: {
            currentPage: 1,
            perPage: 10,
            totalPages: 0,
        },
        lastUpdated: null,
    },
    selected: null,
    popular: null,
}

const FeedReducer: Reducer<FeedState, FeedActions> = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case GET_FEED_SUCCESS:
                draft.data.feed = action.payload.data
                draft.data.pagination.totalPages = action.payload.totalPages
                draft.data.lastUpdated = moment().toDate()
                break
            case GET_FEED_ADDITIONAL_SUCCESS:
                draft.data.feed = [...draft.data.feed, ...action.payload]
                draft.data.pagination.currentPage = draft.data.pagination.currentPage + 1
                break
            case GET_FEED_BY_ID_SUCCESS:
                draft.selected = action.payload
                break
            case LIKE_FEED_SUCCESS:
                if (draft.selected?.id === action.payload.id) {
                    draft.selected.likes = action.payload.data
                }
                draft.data.feed = draft.data.feed.map((feed: Feed) => {
                    if (feed.id === action.payload.id) {
                        feed.likes = action.payload.data
                    }
                    return feed
                })
                break
            case UNLIKE_FEED_SUCCESS:
                if (draft.selected?.id === action.payload.id) {
                    draft.selected.likes = action.payload.data
                }
                draft.data.feed = draft.data.feed.map((feed: Feed) => {
                    if (feed.id === action.payload.id) {
                        feed.likes = action.payload.data
                    }
                    return feed
                })
                break
            case COMMENT_FEED_SUCCESS:
                draft.data.feed.forEach((feed: Feed) => {
                    if (feed.id === action.payload.comments.photo.id) {
                        feed.commentsAll.push(action.payload.comments)
                    }
                })
                draft.selected!.commentsAll.push(action.payload.comments)
                break
            case SELECT_FEED:
                draft.selected = action.payload
                break
            case CLEAR_FEED:
                draft.selected = initialState.selected
                break
            case GET_POPULAR_FEED_SUCCESS:
                draft.popular = action.payload
                break
        }
    })

export default FeedReducer
