import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

const PopularPhotoSkeleton: React.FC = () => {
    return (
        <SkeletonTheme color="#E8E8F0" highlightColor="#FFFFFF">
            <Skeleton height={400} style={{ borderRadius: 20 }} />
            <div className={'width-f flex-j-c'} style={{ margin: '7px 0' }}>
                <div className={'flex-j-s flex-w'} style={{ marginTop: 15, marginLeft: 30 }}>
                    <Skeleton height={7} width={480} />
                    <Skeleton height={7} width={420} />
                </div>
            </div>
        </SkeletonTheme>
    )
}

export default PopularPhotoSkeleton
