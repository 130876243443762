import React from 'react'
import styles from './styles.module.sass'

interface SocialMediaButtonProps {
    label: string
    icon: string
    action: () => void
}

const SocialMediaButton: React.FC<SocialMediaButtonProps> = ({ label, icon, action }) => {
    return (
        <div className={styles.container} onClick={action}>
            <div className={'flex-a-c'}>
                <img src={icon} alt="Google img" width={20} />
                <span className={styles.text}>{label}</span>
            </div>
        </div>
    )
}

export default SocialMediaButton
