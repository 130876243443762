import { Action } from 'redux'

export const GET_PRIVACY_REQUEST = '@@pages/GET_PRIVACY_REQUEST'
export const GET_PRIVACY_SUCCESS = '@@pages/GET_PRIVACY_SUCCESS'
export const GET_TERMS_REQUEST = '@@pages/GET_TERMS_REQUEST'
export const GET_TERMS_SUCCESS = '@@pages/GET_TERMS_SUCCESS'

export interface PrivacyState {
    privacy: string
    terms: string
}

export interface GetPrivacyRequest extends Action {
    type: typeof GET_PRIVACY_REQUEST
}

export interface GetPrivacySuccess extends Action {
    type: typeof GET_PRIVACY_SUCCESS
    payload: string
}

export interface GetTermsRequest extends Action {
    type: typeof GET_TERMS_REQUEST
}

export interface GetTermsSuccess extends Action {
    type: typeof GET_TERMS_SUCCESS
    payload: string
}

export type PrivacyActions = GetPrivacyRequest | GetPrivacySuccess | GetTermsRequest | GetTermsSuccess
