import React from 'react'
import styles from './styles.module.sass'
import * as ProfileAssets from '../../../pages/profile/assets'

interface NothingFoundBlockProps {
    label: string
    text: string
}

const NothingFoundBlock: React.FC<NothingFoundBlockProps> = ({ label, text }) => {
    return (
        <div className={styles.emptyMainBox}>
            <div className={styles.emptyLabel}>{label}</div>
            <div className={'ta-c'} style={{ marginTop: '48px' }}>
                <img src={ProfileAssets.Friends} alt="Profile friends image" width={'400px'} />
            </div>
            <div className={`ta-c ${styles.placeText}`}>{text}</div>
        </div>
    )
}

export default NothingFoundBlock
