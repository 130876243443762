import { Action } from 'redux'

export const START_ACTION = 'LOADER_START_ACTION'
export const STOP_ACTION = 'LOADER_STOP_ACTION'

export type LoadingActionType = {
    name: string
    // tslint:disable-next-line:no-any
    params: any
}

export interface LoaderState {
    actions: LoadingActionType[]
}

export interface StartAction extends Action {
    type: typeof START_ACTION
    payload: LoadingActionType
}

export interface StopAction extends Action {
    type: typeof STOP_ACTION
    payload: LoadingActionType
}

export type LoaderActions = StartAction | StopAction
