const links = {
    login: '/login',
    register: '/register',
    forgotPassword: '/forgot-password',
    feed: '/feed',
    feedPhoto: '/feed/:photoId',
    terms: '/terms',
    privacy: '/privacy-policy',
    user: '/user/:userId',
    search: '/search',
    profile: '/profile',
    tooManyAttempts: '/too-many-attempts',
}

export default links
