import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import styles from './styles.module.sass'

const MyFriendsSkeleton: React.FC = () => (
    <div className={styles.myFriendsSkeleton}>
        <SkeletonTheme color="#E8E8F0">
            <div className={'width-f height-f flex-d-r flex-j-a flex-w justify-content-center'}>
                {Array.from({ length: 8 }, (el, index: number) => (
                    <div className={`width-q flex-d-c flex-a-c` + (index < 4 ? ' mb-4' : '')} key={index}>
                        <Skeleton circle={true} width={50} height={50} style={{ lineHeight: 2 }} />
                        <Skeleton width={50} height={5} style={{ marginTop: '15px', lineHeight: index < 4 ? 0.3 : 0 }} />
                    </div>
                ))}
            </div>
        </SkeletonTheme>
    </div>
)

export default MyFriendsSkeleton
