import React, { useCallback, useMemo, useState } from 'react'
import styles from './styles.module.sass'
import Avatar from 'react-avatar'
import { renderProfileName, renderUserName } from '../../../../../utils/common'
import { Link } from 'react-router-dom'
import { User } from '../../../../../entities/User'
import useIsInSentPending from '../../../../../utils/hooks/useIsInSentPending'
import { useSelector } from 'react-redux'
import { countriesSelector } from '../../../../../store/countries/countries.selectors'
import _ from 'lodash'
import useIsMyFriend from '../../../../../utils/hooks/useIsMyFriend'

interface UserItemProps {
    user: User
    addAction: (callback: () => void) => void
}

const UserItem: React.FC<UserItemProps> = ({ user, addAction }) => {
    const isSentPending = useIsInSentPending(user.id)
    const countries = useSelector(countriesSelector)
    const [disabled, setDisabled] = useState<boolean>(false)
    const isMyFriend = useIsMyFriend(Number(user.id))

    const userCountry = useMemo(() => {
        const country = _.find(countries, { id: user?.country_id })
        // @ts-ignore
        return country?.name || ''
    }, [countries, user])
    const handleToggleDisabled = useCallback(() => {
        setDisabled((prevState) => !prevState)
    }, [setDisabled])

    return (
        <div className={`${styles.peopleItem} flex-d-r flex-a-c flex-j-b`} key={user.id}>
            <div className={'flex-a-c'}>
                <Avatar src={user.avatar} name={renderUserName(user)} className={`${styles.avatar} mr-3`} size={'50px'} />
                <div className={`flex-d-c`}>
                    <Link className={styles.link} to={`/user/${user.id}`}>
                        <span className={styles.name}> {user.nickname.length > 8 ? `${user.nickname.slice(0, 8)}...` : user.nickname}</span>
                    </Link>
                    <div className={'flex-d-c'}>
                        <span className={styles.subText}>{renderProfileName(user!)}</span>
                        <span className={styles.subText}>{`${user?.city || ''}${userCountry && user.city ? `, ` : ''}${
                            userCountry ? `${userCountry}` : ''
                        }`}</span>
                    </div>
                </div>
            </div>
            {isMyFriend ? (
                <button className={`${styles.button}`} style={{ padding: '6px', backgroundColor: '#15593E' }} disabled>
                    <span>Accepted</span>
                </button>
            ) : isSentPending ? (
                <button className={`${styles.button}`} style={{ padding: '6px', backgroundColor: '#15593E' }} disabled>
                    <span>Sent</span>
                </button>
            ) : (
                <button
                    className={`${styles.button}`}
                    style={{ padding: '6px' }}
                    onClick={() => {
                        handleToggleDisabled()
                        addAction(() => {
                            handleToggleDisabled()
                        })
                    }}
                    disabled={disabled}
                >
                    <span>Add</span>
                </button>
            )}
        </div>
    )
}

export default UserItem
