import React, { useContext, useState } from 'react'
import { Form, Formik } from 'formik'
import { AuthFormFields, SignUpSchema } from '../../utils/validation/auth'
import AuthFormField from '../../common/components/AuthFormField'
import AuthConfirmButton from '../../common/components/AuthConfirmButton'
import { PageType } from '../login'
import styles from '../login/styles.module.sass'
import SocialMediaButton from '../../common/components/SocialMediaButton'
import * as LoginAssets from '../login/assets'
import { useSelector } from 'react-redux'
import { loadingActionSelector } from '../../store/loader/loader.selectors'
import { FACEBOOK_AUTH_REQUEST, GOOGLE_AUTH_REQUEST, REGISTER_REQUEST } from '../../store/auth/auth.types'
import { useActions } from '../../utils/hooks/useActions'
import { facebookAuthRequest, googleAuthRequest, registerRequest } from '../../store/auth/auth.actions'
import GoogleLogin from 'react-google-login'
import { socialAuthConfig } from '../../utils/socialAuth'
import { AuthContext, AuthContextType } from '../../common/contexts/authContext'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

export interface CredentialsInvalidLabels {
    email: string
    nickname: string
}

const Register: React.FC = () => {
    const loading = useSelector(loadingActionSelector)([REGISTER_REQUEST, GOOGLE_AUTH_REQUEST, FACEBOOK_AUTH_REQUEST])
    const [registerActionDispatch, googleAuthActionDispatch, facebookAuthActionDispatch] = useActions([
        registerRequest,
        googleAuthRequest,
        facebookAuthRequest,
    ])
    const { onAuthSuccess } = useContext<AuthContextType>(AuthContext)

    const [credentialsInvalidLabel, setCredentialsInvalidLabel] = useState<CredentialsInvalidLabels>({ email: '', nickname: '' })

    return (
        <>
            <Formik<AuthFormFields>
                validateOnBlur={false}
                validateOnChange={false}
                initialValues={{ email: '', password: '', nickname: '' }}
                validationSchema={SignUpSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true)
                    registerActionDispatch({
                        data: values,
                        onSuccess: onAuthSuccess,
                        onError: (labels?: CredentialsInvalidLabels) => {
                            if (labels) {
                                setCredentialsInvalidLabel(labels)
                            }
                            setSubmitting(false)
                        },
                    })
                    setSubmitting(false)
                }}
            >
                {({ errors, submitForm, isSubmitting }) => (
                    <Form className={'width-f'}>
                        <AuthFormField
                            name={'nickname'}
                            error={credentialsInvalidLabel.nickname || errors.nickname}
                            type={'text'}
                            placeholder={'Nickname'}
                            disabled={isSubmitting}
                        />
                        <AuthFormField
                            name={'email'}
                            error={credentialsInvalidLabel.email || errors.email}
                            type={'text'}
                            placeholder={'Email'}
                            disabled={isSubmitting}
                        />
                        <AuthFormField name={'password'} error={errors.password} type={'password'} placeholder={'Password'} disabled={isSubmitting} />
                        <div className={'mt-8'}>
                            <AuthConfirmButton loading={loading} type={PageType.SignUp} action={submitForm} />
                        </div>
                        {/*{credentialsInvalid && (*/}
                        {/*    <div className={'width-f ta-c mt-2'}>*/}
                        {/*        <span className={'error-text '}>{credentialsInvalidLabel || 'The given data is invalid'}</span>*/}
                        {/*    </div>*/}
                        {/*)}*/}
                    </Form>
                )}
            </Formik>
            <div className={`${styles.orBox} width-f`}>
                <div className={styles.horizontalLine} />
                <span>or</span>
                <div className={styles.horizontalLine} />
            </div>
            <div className={`${styles.fullWidth} ${styles.flexJustifyAround}`}>
                <GoogleLogin
                    clientId={socialAuthConfig.google.appId as string}
                    onSuccess={(response) =>
                        googleAuthActionDispatch({
                            data: response,
                            onError: () => {},
                            onSuccess: onAuthSuccess,
                        })
                    }
                    render={({ onClick }) => <SocialMediaButton action={onClick} label={'Google'} icon={LoginAssets.Google} />}
                />
                <FacebookLogin
                    appId={socialAuthConfig.facebook.appId}
                    /* tslint:disable-next-line:no-any */
                    callback={(value: any) =>
                        facebookAuthActionDispatch({
                            data: value.accessToken,
                            onError: () => {},
                            onSuccess: onAuthSuccess,
                        })
                    }
                    /* tslint:disable-next-line:no-any */
                    render={(props: any) => <SocialMediaButton label={'Facebook'} icon={LoginAssets.FB} action={props.onClick} />}
                />
            </div>
        </>
    )
}

export default Register
