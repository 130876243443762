import React, { useEffect } from 'react'
import styles from './styles.module.sass'
import MyFriendsSkeleton from '../../loaders/MyFriendsSkeleton'
import { Link } from 'react-router-dom'
import Avatar from 'react-avatar'
import { renderUserName } from '../../../utils/common'
import { useSelector } from 'react-redux'
import { loadingActionSelector } from '../../../store/loader/loader.selectors'
import { GET_FRIENDS_REQUEST } from '../../../store/friends/friends.types'
import { myFriendsSelector } from '../../../store/friends/friends.selectors'
import { useActions } from '../../../utils/hooks/useActions'
import { getFriendsRequest } from '../../../store/friends/friends.actions'
import { userSelector } from '../../../store/auth/auth.selectors'
import _ from 'lodash'

interface FriendsProps {
    scrollToTop?: () => void
}

const Friends: React.FC<FriendsProps> = ({ scrollToTop }) => {
    const myProfile = useSelector(userSelector)
    const loading = useSelector(loadingActionSelector)([GET_FRIENDS_REQUEST])
    const friends = useSelector(myFriendsSelector)
    const [getFriendsAction] = useActions([getFriendsRequest])

    useEffect(() => {
        if (_.isEmpty(friends)) {
            getFriendsAction()
        }
        if (scrollToTop) {
            scrollToTop()
        }
    }, [getFriendsAction, myProfile])

    return (
        <div className={`${styles.container} shadow`}>
            <span className={styles.title}>My friends</span>
            {loading || !myProfile || friends.length ? null : <span className={styles.placeholder}>Your friends list is empty :(</span>}
            <div className={`${styles.friendsContainer} mt-4`}>
                {loading || !myProfile ? (
                    <MyFriendsSkeleton />
                ) : (
                    <>
                        {friends.length
                            ? friends.slice(0, 4).map((user) => (
                                  <div className={`flex-d-c flex-a-c mb-4 width-q`} key={user.id}>
                                      <Link to={`/user/${user.id}`} className={` ${styles.link} flex-centred flex-d-c`}>
                                          <Avatar
                                              className={styles.avatar}
                                              size={'50'}
                                              round={true}
                                              name={renderUserName(user, false, 6)}
                                              src={user.avatar}
                                              maxInitials={4}
                                          />
                                          <span className={`${styles.name} mt-2`}>{renderUserName(user, false, 6)}</span>
                                      </Link>
                                  </div>
                              ))
                            : null}
                    </>
                )}
            </div>
            {!loading && friends.length > 4 && (
                <div className={`width-f flex-j-s mb-1`}>
                    {loading ? null : (
                        <>
                            {friends.slice(4, 7).map((user) => (
                                <div className={`flex-d-c flex-a-c width-q`} key={user.id}>
                                    <Link to={`/user/${user.id}`} className={` ${styles.link} flex-centred flex-d-c`}>
                                        <Avatar
                                            className={styles.avatar}
                                            round={true}
                                            size={'50'}
                                            name={renderUserName(user, false, 6)}
                                            src={user.avatar}
                                        />
                                        <span className={`${styles.name} mt-2`}>{renderUserName(user, false, 6)}</span>
                                    </Link>
                                </div>
                            ))}
                            {friends.length >= 8 && (
                                <div className={`flex-d-c flex-a-c width-q`}>
                                    <Link to={`/profile`} className={` ${styles.link} flex-centred flex-d-c`}>
                                        <Avatar
                                            style={{
                                                background: '#000000',
                                                opacity: 0.42,
                                            }}
                                            color={'#000000'}
                                            className={'ta-c'}
                                            round={true}
                                            size={'50'}
                                            value={`+${Number(friends.length) - 7}`}
                                        />
                                        <span className={`${styles.name} mt-2`}>Others</span>
                                    </Link>
                                </div>
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    )
}

export default Friends
