import { useSelector } from 'react-redux'
import { pendingSentSelector } from '../../store/friends/friends.selectors'
import { useMemo } from 'react'
import _ from 'lodash'

const useIsInSentPending = (userId: number) => {
    const pending = useSelector(pendingSentSelector)
    return useMemo(() => _.find(pending, { id: userId }) !== undefined, [pending, userId])
}

export default useIsInSentPending
