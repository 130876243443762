import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { isLoggedSelector } from '../../../store/auth/auth.selectors'

interface PublicRouteProps {
    // tslint:disable-next-line:no-any
    component: React.FC<any>
    restricted?: boolean

    // tslint:disable-next-line:no-any
    [key: string]: any
}

const PublicRoute: React.FC<PublicRouteProps> = ({ component: Component, restricted, ...rest }) => {
    const isLogged = useSelector(isLoggedSelector)
    return (
        // restricted = false meaning public route
        // restricted = true meaning restricted route
        <Route {...rest} render={(props) => (isLogged && restricted ? <Redirect to={'/feed'} /> : <Component {...props} />)} />
    )
}

export default PublicRoute
