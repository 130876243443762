import { CountriesActions, CountriesState, GET_COUNTRIES_SUCCESS } from './countries.types'
import { Reducer } from 'redux'
import produce from 'immer'

const initialState: CountriesState = {
    data: [],
}

const CountriesReducer: Reducer<CountriesState, CountriesActions> = (state = initialState, action: CountriesActions) =>
    produce(state, (draft) => {
        switch (action.type) {
            case GET_COUNTRIES_SUCCESS:
                draft.data = action.payload
                break
        }
    })

export default CountriesReducer
