import { LoadingActionType, START_ACTION, StartAction, STOP_ACTION, StopAction } from './loader.types'
import { ActionCreator } from 'redux'

export const startAction: ActionCreator<StartAction> = (payload: LoadingActionType) => ({
    type: START_ACTION,
    payload,
})

export const stopAction: ActionCreator<StopAction> = (payload: LoadingActionType) => ({
    type: STOP_ACTION,
    payload,
})
