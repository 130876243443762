import React from 'react'
import styles from './styles.module.sass'
import { Link } from 'react-router-dom'

const TermsAndConditions: React.FC = () => {
    return (
        <div className={`width-f ${styles.policyContainer}`}>
            <span>
                <Link to={'/privacy-policy'} className={styles.underlineText}>
                    Privacy Policy
                </Link>
                {` and `}
                <Link to={'/terms'} className={styles.underlineText}>
                    Terms and Conditions
                </Link>
            </span>
        </div>
    )
}

export default TermsAndConditions
