import React, { useCallback, useMemo, useState } from 'react'
import { Feed } from '../../../../entities/Feed'
import styles from './styles.module.sass'
import Avatar from 'react-avatar'
import moment from 'moment'
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    PinterestShareButton,
    PinterestIcon,
    LinkedinShareButton,
    LinkedinIcon,
} from 'react-share'
import { renderUserName } from '../../../../utils/common'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import cardStyles from '../../../publication/card.module.sass'
import * as Assets from './assets'

interface FeedCardProps {
    item: Feed
    onLike: (callback: () => void) => void
    onComment?: () => void
    handleLoaded?: () => void
    publicationPage?: boolean
    fullWidth?: boolean
}

const FeedCard: React.FC<FeedCardProps> = ({ item, onLike, onComment, handleLoaded, publicationPage, fullWidth }) => {
    const [sharing, setSharing] = useState<boolean>(false)
    const [likeDisabled, setLikeDisabled] = useState<boolean>(false)
    const [modal, setModal] = useState<boolean>(false)

    const handleToggleSharing = useCallback(() => {
        setSharing((prevState) => !prevState)
    }, [setSharing])

    const handleToggleDisabled = useCallback(() => {
        setLikeDisabled((prevState) => !prevState)
    }, [setLikeDisabled])

    const handleToggleModal = useCallback(() => {
        setModal((prevState) => !prevState)
    }, [setModal])

    const shareUrl = useMemo(() => `${process.env.REACT_APP_HOST_URL}/feed/${item.id}`, [item])

    return (
        <div className={`${styles.container} ${!publicationPage && 'shadow'} ${fullWidth && 'width-f'}`}>
            <div className={'flex-d-r flex-a-c mb-3 ta-l'} style={{ textAlign: 'left' }}>
                <Avatar
                    name={renderUserName(item.user)}
                    src={item.user.avatar}
                    unstyled={!_.isEmpty(item.user.avatar)}
                    className={`${styles.avatar} mr-2`}
                    round={true}
                    size={'50px'}
                />
                <div className={'flex-d-c flex-j-a'}>
                    <Link to={`/user/${item.user.id}`} style={{ color: '#363636' }}>
                        <span className={styles.userName}>{renderUserName(item.user)}</span>
                    </Link>
                    <span className={styles.metaInfo}>{`${moment(item.created_at).format('hh:mm A, MM.DD.yyyy')} ${item.location.location}`}</span>
                </div>
            </div>
            {item.type === 'video' ? (
                <div
                    className={styles.videoOverlay}
                    onClick={(e) => {
                        e.preventDefault()
                        handleToggleModal()
                    }}
                >
                    <img src={Assets.play} className={styles.playIcon} />
                    <video onLoad={handleLoaded} autoPlay={false} muted className={styles.photo} style={{ width: publicationPage ? '80%' : '100%' }}>
                        <source src={item.media.original} type="video/mp4" />
                    </video>
                </div>
            ) : (
                <img
                    onLoad={handleLoaded}
                    onClick={handleToggleModal}
                    src={item.media.original}
                    alt="Post media must be there"
                    className={styles.photo}
                    style={{ width: publicationPage ? '80%' : '100%' }}
                />
            )}
            <div className={`mt-3 ${styles.descriptionBox}`}>
                <span className={styles.commentBody}>{item.description}</span>
            </div>
            <div className={`${styles.delim} mt-2`} />
            <div className={`flex-d-r mt-4`}>
                <div className={'flex-d-r flex-a-c'}>
                    <div
                        className={`${styles.shareBox} mr-3`}
                        onClick={() => {
                            if (!likeDisabled) {
                                handleToggleDisabled()
                                onLike(handleToggleDisabled)
                            }
                        }}
                    >
                        <svg width="28" height="23" viewBox="0 0 28 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M24.587 1.95669C26.2125 3.27698 27.0772 4.96035 27.1809 7.00677C27.2847 9.05323 26.593 10.7861 25.1058 12.2054L15.0932 22.1075C14.7474 22.4376 14.3497 22.6026 13.9 22.6026C13.4504 22.6026 13.0527 22.4376 12.7068 22.1075L2.6943 12.2054C1.20711 10.7861 0.515402 9.05323 0.619159 7.00677C0.722915 4.96035 1.58756 3.27698 3.21308 1.95669C4.63109 0.801446 6.25663 0.306338 8.08966 0.471374C9.95726 0.603402 11.5482 1.31306 12.8625 2.60033L13.9 3.59055L14.9376 2.60033C16.2519 1.31306 17.8255 0.603402 19.6586 0.471374C21.5262 0.306338 23.169 0.801446 24.587 1.95669Z"
                                fill="#F70402"
                            />
                        </svg>
                    </div>
                    <span style={{ minWidth: '15px' }} className={'mr-4'}>
                        {item.likes.length}
                    </span>
                </div>
                <div className={'flex-d-r flex-a-c'}>
                    <div className={`${styles.shareBox} mr-3`} onClick={onComment}>
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M3.28032 3.18716C5.29747 1.47873 7.73151 0.624512 10.5825 0.624512C13.4334 0.624512 15.8674 1.47873 17.8846 3.18716C19.9017 4.8956 20.9103 6.95409 20.9103 9.36272C20.9103 11.7713 19.9017 13.8298 17.8846 15.5383C15.8674 17.2467 13.4334 18.1009 10.5825 18.1009C9.0763 18.1009 7.63739 17.8349 6.26571 17.3027C4.51751 18.7311 2.62138 19.4453 0.577324 19.4453C0.523533 19.4453 0.469742 19.4173 0.415951 19.3612C0.36216 19.3332 0.321816 19.2912 0.294921 19.2352C0.24113 19.0952 0.254578 18.9692 0.335264 18.8571C0.442846 18.7731 0.604218 18.577 0.819382 18.269C1.06144 17.9889 1.37074 17.5128 1.74728 16.8406C2.12382 16.1404 2.39277 15.4823 2.55414 14.8661C1.0211 13.2697 0.254578 11.4352 0.254578 9.36272C0.254578 6.95409 1.26316 4.8956 3.28032 3.18716Z"
                                fill="#13267E"
                            />
                        </svg>
                    </div>
                    <span className={'mr-7'}>{item.commentsAll.length}</span>
                </div>
                <div onClick={handleToggleSharing} className={`${publicationPage ? 'mr-10' : ''}`}>
                    <div className={`${styles.shareBox} mr-3`}>
                        <svg width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M15.9285 14.2849C17.119 14.2849 18.1309 14.686 18.9643 15.488C19.7975 16.2901 20.2142 17.2641 20.2142 18.4099C20.2142 19.5557 19.7975 20.5297 18.9643 21.3318C18.1309 22.1339 17.119 22.5349 15.9285 22.5349C14.738 22.5349 13.7262 22.1339 12.8928 21.3318C12.0595 20.5297 11.6428 19.5557 11.6428 18.4099C11.6428 18.0948 11.6726 17.794 11.7321 17.5076L7.1785 14.7576C6.40471 15.3591 5.51183 15.6599 4.49995 15.6599C3.30947 15.6599 2.29757 15.2589 1.46423 14.4568C0.6309 13.6547 0.214233 12.6807 0.214233 11.5349C0.214233 10.3891 0.6309 9.41514 1.46423 8.61302C2.29757 7.81094 3.30947 7.40991 4.49995 7.40991C5.51183 7.40991 6.40471 7.71069 7.1785 8.31224L11.7321 5.56224C11.6726 5.2758 11.6428 4.97503 11.6428 4.65991C11.6428 3.51408 12.0595 2.54012 12.8928 1.73804C13.7262 0.935954 14.738 0.534912 15.9285 0.534912C17.119 0.534912 18.1309 0.935954 18.9643 1.73804C19.7975 2.54012 20.2142 3.51408 20.2142 4.65991C20.2142 5.80575 19.7975 6.77969 18.9643 7.58181C18.1309 8.38389 17.119 8.78491 15.9285 8.78491C14.9166 8.78491 14.0238 8.48414 13.25 7.88258L8.69637 10.6326C8.75591 10.919 8.78566 11.2198 8.78566 11.5349C8.78566 11.85 8.75591 12.1508 8.69637 12.4372L13.25 15.1872C14.0238 14.5857 14.9166 14.2849 15.9285 14.2849Z"
                                fill="#098B57"
                            />
                        </svg>
                    </div>
                </div>
                {sharing && (
                    <div className={`${styles.shareBar} flex-a-c`}>
                        {publicationPage && <span className={'mr-4'}>Share</span>}
                        <FacebookShareButton url={shareUrl} className={'mr-2'}>
                            <FacebookIcon size={30} round={true} />
                        </FacebookShareButton>
                        <TwitterShareButton url={shareUrl} className={'mr-2'}>
                            <TwitterIcon size={30} round={true} />
                        </TwitterShareButton>
                        <PinterestShareButton url={shareUrl} media={item.media.original} className={'mr-2'}>
                            <PinterestIcon size={30} round={true} />
                        </PinterestShareButton>
                        <LinkedinShareButton url={shareUrl}>
                            <LinkedinIcon size={30} round={true} />
                        </LinkedinShareButton>
                    </div>
                )}
            </div>

            {item && (
                <Modal show={modal} onHide={handleToggleModal} centered>
                    <Modal.Dialog style={{ margin: '10px 10px' }}>
                        {item!.type === 'video' ? (
                            <video controls muted width={'100%'} autoPlay={true} className={cardStyles.media}>
                                <source src={item!.media.original} type="video/mp4" />
                            </video>
                        ) : (
                            <img src={item!.media['480']} alt="Post media must be there" />
                        )}
                    </Modal.Dialog>
                </Modal>
            )}
        </div>
    )
}

export default FeedCard
