import { Feed } from '../../entities/Feed'
import { Action } from 'redux'
import { Like } from '../../entities/Like'
import { CommentFeedRequestType } from '../../entities/requests'
import { CommentFeedResponseType } from '../../entities/responses'

export const GET_FEED_REQUEST = '@feed/GET_FEED_REQUEST'
export const GET_FEED_SUCCESS = '@feed/GET_FEED_SUCCESS'
export const GET_FEED_ADDITIONAL_REQUEST = '@feed/GET_FEED_ADDITIONAL_REQUEST'
export const GET_FEED_ADDITIONAL_SUCCESS = '@feed/GET_FEED_ADDITIONAL_SUCCESS'
export const GET_POPULAR_FEED_REQUEST = '@feed/GET_POPULAR_FEED_REQUEST'
export const GET_POPULAR_FEED_SUCCESS = '@feed/GET_POPULAR_FEED_SUCCESS'
export const GET_FEED_BY_ID_REQUEST = '@@feed/GET_FEED_BY_ID_REQUEST'
export const GET_FEED_BY_ID_SUCCESS = '@@feed/GET_FEED_BY_ID_SUCCESS'
export const LIKE_FEED_REQUEST = '@feed/LIKE_FEED_REQUEST'
export const LIKE_FEED_SUCCESS = '@feed/LIKE_FEED_SUCCESS'
export const UNLIKE_FEED_REQUEST = '@feed/UNLIKE_FEED_REQUEST'
export const UNLIKE_FEED_SUCCESS = '@feed/UNLIKE_FEED_SUCCESS'
export const COMMENT_FEED_REQUEST = '@feed/COMMENT_FEED_REQUEST'
export const COMMENT_FEED_SUCCESS = '@feed/COMMENT_FEED_SUCCESS'
export const SELECT_FEED = '@feed/SELECT_FEED'
export const CLEAR_FEED = '@feed/CLEAR_FEED'

export interface FeedState {
    data: {
        feed: Feed[]
        pagination: {
            currentPage: number
            perPage: number
            totalPages: number
        }
        lastUpdated: Date | null
    }
    selected: Feed | null
    popular: Feed | null
}

export interface GetFeedRequest extends Action {
    type: typeof GET_FEED_REQUEST
}

export interface GetFeedSuccess extends Action {
    type: typeof GET_FEED_SUCCESS
    payload: {
        data: Feed[]
        totalPages: number
    }
}

export interface GetFeedAdditionalRequest extends Action {
    type: typeof GET_FEED_ADDITIONAL_REQUEST
    payload: number
}

export interface GetFeedAdditionalSuccess extends Action {
    type: typeof GET_FEED_ADDITIONAL_SUCCESS
    payload: Feed[]
}

export interface GetFeedByIdRequest extends Action {
    type: typeof GET_FEED_BY_ID_REQUEST
    payload: {
        id: string
        onError: () => void
    }
}

export interface GetFeedByIdSuccess extends Action {
    type: typeof GET_FEED_BY_ID_SUCCESS
    payload: Feed
}

export interface LikeFeedRequest extends Action {
    type: typeof LIKE_FEED_REQUEST
    payload: {
        id: string
        onRequestEnd: () => void
    }
}

export interface LikeFeedSuccess extends Action {
    type: typeof LIKE_FEED_SUCCESS
    payload: {
        id: number
        data: Like[]
    }
}

export interface UnlikeFeedRequest extends Action {
    type: typeof UNLIKE_FEED_REQUEST
    payload: {
        id: string
        onRequestEnd: () => void
    }
}

export interface UnlikeFeedSuccess extends Action {
    type: typeof UNLIKE_FEED_SUCCESS
    payload: {
        id: number
        data: Like[]
    }
}

export interface CommentFeedRequest extends Action {
    type: typeof COMMENT_FEED_REQUEST
    payload: CommentFeedRequestType
}

export interface CommentFeedSuccess extends Action {
    type: typeof COMMENT_FEED_SUCCESS
    payload: CommentFeedResponseType
}

export interface GetPopularFeedRequest extends Action {
    type: typeof GET_POPULAR_FEED_REQUEST
}

export interface GetPopularFeedSuccess extends Action {
    type: typeof GET_POPULAR_FEED_SUCCESS
    payload: Feed
}

export interface SelectFeed extends Action {
    type: typeof SELECT_FEED
    payload: Feed
}

export interface ClearFeed extends Action {
    type: typeof CLEAR_FEED
}

export type FeedActions =
    | GetFeedRequest
    | GetFeedSuccess
    | GetFeedAdditionalRequest
    | GetFeedAdditionalSuccess
    | GetPopularFeedRequest
    | GetPopularFeedSuccess
    | GetFeedByIdRequest
    | GetFeedByIdSuccess
    | LikeFeedRequest
    | LikeFeedSuccess
    | UnlikeFeedRequest
    | UnlikeFeedSuccess
    | CommentFeedRequest
    | CommentFeedSuccess
    | SelectFeed
    | ClearFeed
