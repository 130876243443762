import React, { useCallback, useState } from 'react'
import styles from './styles.module.sass'
import * as Assets from './assets'
import Avatar from 'react-avatar'
import { useActions } from '../../../utils/hooks/useActions'
import { logout } from '../../../store/auth/auth.actions'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../store/auth/auth.selectors'
import { renderUserName } from '../../../utils/common'
import { Link } from 'react-router-dom'
import { searchUserRequest } from '../../../store/friends/friends.actions'
import { useNavigation } from '../../../utils/hooks/useNavigation'
import links from '../../../utils/links'
import Dropdown from 'react-bootstrap/Dropdown'

interface MainLayoutProps {
    withAvatar: boolean
    withSearch: boolean
}

const MainLayout: React.FC<MainLayoutProps> = ({ withSearch, withAvatar, children }) => {
    const user = useSelector(userSelector)
    const [logoutAction, searchAction] = useActions([logout, searchUserRequest])
    const { navigateTo } = useNavigation()

    const [search, setSearch] = useState<string>('')

    const handleSearchUser = useCallback(
        (value: string) => {
            searchAction({
                keyword: value,
                onSuccess: () => navigateTo(links.search),
            })
        },
        [searchUserRequest]
    )

    const handleChangeSearch = useCallback(
        (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            setSearch(e.target.value)
        },
        [setSearch]
    )

    type CustomToggleProps = {
        children?: React.ReactNode
        onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {}
    }
    const CustomToggle = React.forwardRef((props: CustomToggleProps, ref: React.Ref<HTMLAnchorElement>) => (
        <a
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault()
                props.onClick(e)
            }}
        >
            {props.children}
        </a>
    ))

    return (
        <div className={styles.background}>
            <header
                className={`${styles.headerContainer} flex-a-c ${!withAvatar && !withSearch ? 'flex-j-e' : 'flex-j-b'}`}
                style={{ paddingTop: withSearch || withAvatar ? '' : '0', paddingBottom: withSearch || withAvatar ? '' : '0' }}
            >
                {withAvatar && (
                    <div className={`${styles.avatarContainer} flex-a-c`}>
                        <Dropdown className={styles.toggle}>
                            <Dropdown.Toggle as={CustomToggle}>
                                <img src={Assets.Arrow} alt="Dropdown" className={styles.dropdownIcon} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={logoutAction}>Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Link className={styles.profileLink} to={'/profile'}>
                            <Avatar
                                src={user?.avatar}
                                round={true}
                                name={renderUserName(user!)}
                                className={`${styles.avatar} ml-2 mr-4`}
                                size={'50px'}
                            />
                            <div className={styles.name}>{renderUserName(user!)}</div>
                        </Link>
                    </div>
                )}
                {withSearch && (
                    <div className={styles.inputBox}>
                        <div className={`${styles.inputContainer} flex-j-b flex-a-c`}>
                            <img src={Assets.Search} alt="Search" width={18} />
                            <input
                                value={search}
                                type={'text'}
                                placeholder={'Search...'}
                                onChange={handleChangeSearch}
                                onKeyPress={(event) => {
                                    if (event.key === 'Enter') {
                                        handleSearchUser(search)
                                    }
                                }}
                                className={styles.input}
                            />
                        </div>
                    </div>
                )}
                <div className={styles.avatarContainer}>
                    <Link to={'/feed'} style={{ padding: 0 }}>
                        <div className={'flex-a-c flex-j-e'}>
                            <img src={Assets.Foot} alt="Foot" width={42} />
                            <img src={Assets.Logo} alt="Logo" width={160} className={'ml-2'} />
                        </div>
                    </Link>
                </div>
            </header>

            <div className={styles.content}>{children}</div>
        </div>
    )
}

export default MainLayout
