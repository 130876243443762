import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import styles from './styles.module.sass'

const FeedCardSkeleton: React.FC = () => (
    <div className={`${styles.skeleton} shadow`}>
        <SkeletonTheme color="#E8E8F0">
            <div className={'flex-a-c'}>
                <Skeleton duration={0} circle={true} height={50} width={50} className={'mb-1'} style={{ lineHeight: 'unset' }} />
                <div className={'flex-d-c width-f ml-3'}>
                    <Skeleton duration={0} height={7} className={styles.text} width={'10%'} style={{ borderRadius: 30, lineHeight: '0px' }} />
                    <Skeleton duration={0} height={5} className={styles.text} width={'70%'} style={{ borderRadius: 30, lineHeight: '4px' }} />
                </div>
            </div>
            <Skeleton height={400} className={`${styles.photo} mb-4`} />
            <div className={'mt-5 mb-5'}>
                <Skeleton duration={0} height={10} count={3} className={styles.text} width={'100%'} />
            </div>
        </SkeletonTheme>
    </div>
)

export default FeedCardSkeleton
