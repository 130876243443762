import { User } from '../../entities/User'
import { Action } from 'redux'
import { Friendship } from '../../entities/Friendship'

export const GET_FRIENDS_REQUEST = '@@friends/GET_FRIENDS_REQUEST'
export const GET_FRIENDS_SUCCESS = '@@friends/GET_FRIENDS_SUCCESS'
export const GET_RECOMMENDED_REQUEST = '@@friends/GET_RECOMMENDED_REQUEST'
export const GET_RECOMMENDED_SUCCESS = '@@friends/GET_RECOMMENDED_SUCCESS'
export const GET_USER_BY_ID_REQUEST = '@@friends/GET_USER_BY_ID_REQUEST'
export const GET_USER_BY_ID_SUCCESS = '@@friends/GET_USER_BY_ID_SUCCESS'
export const GET_RECEIVED_REQUESTS_REQUEST = '@@friends/GET_RECEIVED_REQUESTS_REQUEST'
export const GET_RECEIVED_REQUESTS_SUCCESS = '@@friends/GET_RECEIVED_REQUESTS_SUCCESS'
export const GET_SENT_REQUESTS_REQUEST = '@@friends/GET_SENT_REQUESTS_REQUEST'
export const GET_SENT_REQUESTS_SUCCESS = '@@friends/GET_SENT_REQUESTS_SUCCESS'
export const ADD_USER_REQUEST = '@@friends/ADD_USER_REQUEST'
export const ADD_USER_SUCCESS = '@@friends/ADD_USER_SUCCESS'
export const ACCEPT_FRIENDSHIP_REQUEST = '@@friends/ACCEPT_FRIENDSHIP_REQUEST'
export const ACCEPT_FRIENDSHIP_SUCCESS = '@@friends/ACCEPT_FRIENDSHIP_SUCCESS'
export const CANCEL_SENT_REQUEST = '@@friends/CANCEL_SENT_REQUEST'
export const CANCEL_SENT_SUCCESS = '@@friends/CANCEL_SENT_SUCCESS'
export const SEARCH_USER_REQUEST = '@@friend/SEARCH_USER_REQUEST'
export const SEARCH_USER_SUCCESS = '@@friend/SEARCH_USER_SUCCESS'
export const CLEAR_SELECTED = '@@friends/CLEAR_SELECTED'
export const RESET_FRIENDS = '@@friends/RESET_FRIENDS'

export interface FriendsState {
    my: User[]
    recommended: User[]
    selected: {
        user: User | null
        myFriend: boolean
        blockedMe: boolean
    }
    requests: {
        received: User[]
        sent: User[]
    }
    search: User[]
}

export interface GetFriendsRequest extends Action {
    type: typeof GET_FRIENDS_REQUEST
    payload: {
        onSuccess: () => void
    }
}

export interface GetFriendsSuccess extends Action {
    type: typeof GET_FRIENDS_SUCCESS
    payload: User[]
}

export interface GetRecommendedRequest extends Action {
    type: typeof GET_RECOMMENDED_REQUEST
}

export interface GetRecommendedSuccess extends Action {
    type: typeof GET_RECOMMENDED_SUCCESS
    payload: User[]
}

export interface GetUserByIdRequest extends Action {
    type: typeof GET_USER_BY_ID_REQUEST
    payload: {
        id: string
        onError: () => void
    }
}

export interface GetUserByIdSuccess extends Action {
    type: typeof GET_USER_BY_ID_SUCCESS
    payload: {
        user: User
        myId: string
    }
}

export interface AddUserRequest extends Action {
    type: typeof ADD_USER_REQUEST
    payload: {
        id: number
        onRequestEnd: () => void
    }
}

export interface AddUserSuccess extends Action {
    type: typeof ADD_USER_SUCCESS
    payload: Friendship
}

export interface AcceptFriendshipRequest extends Action {
    type: typeof ACCEPT_FRIENDSHIP_REQUEST
    payload: {
        user: User
        onSuccess: () => void
    }
}

export interface AcceptFriendshipSuccess extends Action {
    type: typeof ACCEPT_FRIENDSHIP_SUCCESS
    payload: User
}

export interface CancelSentRequest extends Action {
    type: typeof CANCEL_SENT_REQUEST
    payload: number
}

export interface CancelSentSuccess extends Action {
    type: typeof CANCEL_SENT_SUCCESS
    payload: number
}

export interface GetSentRequestsRequest extends Action {
    type: typeof GET_SENT_REQUESTS_REQUEST
}

export interface GetSentRequestsSuccess extends Action {
    type: typeof GET_SENT_REQUESTS_SUCCESS
    payload: User[]
}

export interface GetReceivedRequestsRequest extends Action {
    type: typeof GET_RECEIVED_REQUESTS_REQUEST
}

export interface GetReceivedRequestsSuccess extends Action {
    type: typeof GET_RECEIVED_REQUESTS_SUCCESS
    payload: User[]
}

export interface SearchUserRequest extends Action {
    type: typeof SEARCH_USER_REQUEST
    payload: {
        keyword: string
        onSuccess: () => void
    }
}

export interface SearchUserSuccess extends Action {
    type: typeof SEARCH_USER_SUCCESS
    payload: User[]
}

export interface ClearSelected extends Action {
    type: typeof CLEAR_SELECTED
}

export interface ResetFriends extends Action {
    type: typeof RESET_FRIENDS
}

export type FriendsActions =
    | GetFriendsRequest
    | GetFriendsSuccess
    | GetRecommendedRequest
    | GetRecommendedSuccess
    | GetUserByIdRequest
    | GetUserByIdSuccess
    | AddUserRequest
    | AddUserSuccess
    | AcceptFriendshipRequest
    | AcceptFriendshipSuccess
    | CancelSentRequest
    | CancelSentSuccess
    | GetSentRequestsRequest
    | GetSentRequestsSuccess
    | GetReceivedRequestsRequest
    | GetReceivedRequestsSuccess
    | SearchUserRequest
    | SearchUserSuccess
    | ClearSelected
    | ResetFriends
