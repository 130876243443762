import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Login from '../pages/login'
import links from '../utils/links'
import Register from '../pages/register'
import AuthLayout from '../common/layouts/AuthLayout'
import ForgotPassword from '../pages/forgotPassword'
import MainLayout from '../common/layouts/MainLayout'
import PrivateRoute from '../common/components/PrivateRoute'
import Feed from '../pages/feed'
import PublicRoute from '../common/components/PublicRoute'
import Publication from '../pages/publication'
import Privacy from '../pages/privacy'
import Terms from '../pages/terms'
import UserPage from '../pages/user'
import SearchPage from '../pages/search'
import Profile from '../pages/profile'
import TooManyAttempts from '../pages/tooManyAtempts'

const Routes: React.FC = () => {
    return (
        <Switch>
            <Route exact path={[links.feed, links.feedPhoto, links.user, links.search, links.profile]}>
                <MainLayout withAvatar={true} withSearch={true}>
                    <PrivateRoute exact path={links.feed} component={Feed} />
                    <PrivateRoute exact path={links.feedPhoto} component={Publication} />
                    <PrivateRoute exact path={links.user} component={UserPage} />
                    <PrivateRoute exact path={links.search} component={SearchPage} />
                    <PrivateRoute exact path={links.profile} component={Profile} />
                </MainLayout>
            </Route>
            <Route exact path={[links.privacy, links.terms]}>
                <MainLayout withAvatar={false} withSearch={false}>
                    <PublicRoute restricted={true} path={links.privacy} component={Privacy} />
                    <PublicRoute restricted={true} path={links.terms} component={Terms} />
                </MainLayout>
            </Route>
            <Route exact path={[links.login, links.register, links.forgotPassword]}>
                <AuthLayout>
                    <PublicRoute restricted={true} path={links.login} component={Login} />
                    <PublicRoute restricted={true} path={links.register} component={Register} />
                    <PublicRoute restricted={true} path={links.forgotPassword} component={ForgotPassword} />
                </AuthLayout>
            </Route>
            <Route exact path={links.tooManyAttempts} component={TooManyAttempts} />
            <Route>
                <Redirect to={'/feed'} />
            </Route>
        </Switch>
    )
}

export default Routes
