import { useSelector } from 'react-redux'
import { myFriendsSelector } from '../../store/friends/friends.selectors'
import { useMemo } from 'react'
import _ from 'lodash'

const useIsMyFriend = (userId: number) => {
    const friends = useSelector(myFriendsSelector)
    return useMemo(() => _.find(friends, { id: userId }) !== undefined, [friends, userId])
}

export default useIsMyFriend
