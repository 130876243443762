import HttpService from './index'
import {
    CommentFeedResponseType,
    GetFeedByIdResponseType,
    GetFeedResponseType,
    LikeFeedResponseType,
    UnlikeFeedResponseType,
} from '../entities/responses'
import { CommentFeedRequestType } from '../entities/requests'

class FeedApi extends HttpService {
    getPublicFeed = (): Promise<GetFeedResponseType> => {
        return this.get('feed/public?page=1')
    }

    getAdditionalPublicFeed = (page: number) => {
        return this.get(`feed/public?page=${page}`)
    }

    getPopularPhoto = () => {
        return this.get('photo/popular')
    }

    getFeedById = (feedId: string): Promise<GetFeedByIdResponseType> => {
        return this.post(`photo/${feedId}/get`)
    }

    likeFeed = (feedId: string): Promise<LikeFeedResponseType> => {
        return this.post(`photo/${feedId}/like`)
    }

    unlikeFeed = (feedId: string): Promise<UnlikeFeedResponseType> => {
        return this.post(`photo/${feedId}/unlike`)
    }

    commentFeed = (data: CommentFeedRequestType): Promise<CommentFeedResponseType> => {
        return this.post(`photo/${data.photoId}/comment/store`, data)
    }
}

export default new FeedApi({})
