import HttpService from './index'
import { SocialAuthRequestType, LoginRequestType, RegisterRequestType, ResetPasswordRequestType } from '../entities/requests'
import { GetUserResponseType, LoginResponseType, RegisterResponseType, ResetPasswordResponseType } from '../entities/responses'

class AuthApi extends HttpService {
    login = (data: LoginRequestType): Promise<LoginResponseType> => {
        return this.post('login', data, false)
    }

    register = (data: RegisterRequestType): Promise<RegisterResponseType> => {
        return this.post('register', { ...data, password_confirmation: data.password }, false)
    }

    resetPassword = (data: ResetPasswordRequestType): Promise<ResetPasswordResponseType> => {
        return this.post('password/email', data, false)
    }

    googleAuth = (data: SocialAuthRequestType): Promise<LoginResponseType> => {
        return this.post(`login/google`, data, false)
    }

    facebookAuth = (data: SocialAuthRequestType): Promise<LoginResponseType> => {
        return this.post(`login/facebook`, data, false)
    }

    getUser = (): Promise<GetUserResponseType> => {
        return this.post('user-details')
    }

    createDeviceToken = (token: string) => {
        return this.post('device-token', { device_token: token, device: 'web' })
    }

    deleteDeviceToken = (token: string) => {
        return this.delete('device-token', { device_token: token })
    }
}

export default new AuthApi({})
