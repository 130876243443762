interface IConfig {
    BACKEND_URL: string
    PAGE_URL: string
}

const config: IConfig = {
    BACKEND_URL: `${process.env.REACT_APP_API_URL}`,
    PAGE_URL: `${process.env.REACT_APP_PAGES_API}`,
}

export default config
