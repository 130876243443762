import {
    AuthActions,
    AuthState,
    CREATE_DEVICE_TOKEN_SUCCESS,
    DELETE_DEVICE_TOKEN_SUCCESS,
    GET_USER_SUCCESS,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    REGISTER_SUCCESS,
} from './auth.types'
import { Reducer } from 'redux'
import produce from 'immer'

const initialState: AuthState = {
    token: null,
    deviceToken: null,
    user: null,
}

const AuthReducer: Reducer<AuthState, AuthActions> = (state = initialState, action: AuthActions) =>
    produce(state, (draft) => {
        switch (action.type) {
            case LOGIN_SUCCESS:
                draft.token = action.payload.token
                draft.user = action.payload.user
                break
            case GET_USER_SUCCESS:
                draft.user = action.payload
                break
            case REGISTER_SUCCESS:
                draft.token = action.payload.token
                break
            case LOGOUT_SUCCESS:
                draft.token = initialState.token
                draft.user = initialState.user
                break
            case CREATE_DEVICE_TOKEN_SUCCESS:
                draft.deviceToken = action.payload
                break
            case DELETE_DEVICE_TOKEN_SUCCESS:
                draft.deviceToken = initialState.deviceToken
                break
        }
    })

export default AuthReducer
