import {
    ACCEPT_FRIENDSHIP_SUCCESS,
    ADD_USER_SUCCESS,
    CANCEL_SENT_SUCCESS,
    CLEAR_SELECTED,
    FriendsActions,
    FriendsState,
    GET_FRIENDS_SUCCESS,
    GET_RECEIVED_REQUESTS_SUCCESS,
    GET_RECOMMENDED_SUCCESS,
    GET_SENT_REQUESTS_SUCCESS,
    GET_USER_BY_ID_SUCCESS,
    RESET_FRIENDS,
    SEARCH_USER_SUCCESS,
} from './friends.types'
import { Reducer } from 'redux'
import produce from 'immer'
import _ from 'lodash'

const initialState: FriendsState = {
    my: [],
    recommended: [],
    selected: {
        user: null,
        myFriend: false,
        blockedMe: false,
    },
    requests: {
        sent: [],
        received: [],
    },
    search: [],
}

const FriendsReducer: Reducer<FriendsState, FriendsActions> = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case GET_FRIENDS_SUCCESS:
                draft.my = action.payload
                break
            case GET_RECOMMENDED_SUCCESS:
                draft.recommended = action.payload
                break
            case GET_USER_BY_ID_SUCCESS:
                draft.selected.user = action.payload.user
                draft.selected.blockedMe = !!_.find(action.payload.user.blockedUsers, { id: action.payload.myId })
                draft.selected.myFriend = !!_.find(action.payload.user.friends, { id: action.payload.myId })
                break
            case CLEAR_SELECTED:
                draft.selected = initialState.selected
                break
            case GET_SENT_REQUESTS_SUCCESS:
                draft.requests.sent = action.payload
                break
            case GET_RECEIVED_REQUESTS_SUCCESS:
                draft.requests.received = action.payload
                break
            case ADD_USER_SUCCESS:
                draft.requests.sent.push(action.payload.receiver)
                break
            case ACCEPT_FRIENDSHIP_SUCCESS:
                draft.my = [...draft.my, action.payload]
                break
            case CANCEL_SENT_SUCCESS:
                draft.requests.sent = _.remove(draft.requests.sent, (r) => r.id === action.payload)
                break
            case SEARCH_USER_SUCCESS:
                draft.search = action.payload
                break
            case RESET_FRIENDS:
                draft.recommended = initialState.recommended
                draft.my = initialState.my
                draft.requests = initialState.requests
                break
        }
    })

export default FriendsReducer
