import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import Routes from './routes'
import { ToastContainer } from 'react-toastify'
import { useActions } from './utils/hooks/useActions'
import { authInit, createDeviceTokenRequest } from './store/auth/auth.actions'
import { loadingActionSelector } from './store/loader/loader.selectors'
import { AUTH_INIT } from './store/auth/auth.types'
import { useSelector } from 'react-redux'
import { requestNotificationPermission } from './utils/notifications'
import { messaging } from './firebase'
import { userSelector } from './store/auth/auth.selectors'
import MobileRedirect from './pages/mobileRedirect'
import 'react-perfect-scrollbar/dist/css/styles.css'

const App: React.FC = () => {
    const [authInitAction, createDeviceTokenAction] = useActions([authInit, createDeviceTokenRequest])
    const loading = useSelector(loadingActionSelector)([AUTH_INIT])
    const profile = useSelector(userSelector)

    const [isNormalSize, setIsNormalSize] = useState<boolean>(true) //false

    useEffect(() => {
        authInitAction()
        if (window.innerWidth >= 920) {
            setIsNormalSize(true)
        }
    }, [authInitAction])

    useEffect(() => {
        if (profile && messaging) {
            messaging
                .getToken({ vapidKey: process.env.REACT_APP_VAPID_PUBLIC_KEY })
                .then((currentToken) => {
                    if (currentToken) {
                        createDeviceTokenAction(currentToken)
                    } else {
                        requestNotificationPermission()
                    }
                    messaging!.onMessage((payload) => {
                        const notificationTitle = payload.notification.title
                        const notificationOptions = {
                            body: payload.notification.body,
                        }
                        // tslint:disable-next-line:no-unused-expression
                        new Notification(notificationTitle, notificationOptions)
                    })
                })
                .catch((err) => {})
        }
    }, [profile])

    return isNormalSize ? (
        <>
            {!loading && (
                <Router>
                    <Routes />
                </Router>
            )}
            <ToastContainer position={'bottom-center'} />
        </>
    ) : (
        <>
            <MobileRedirect />
        </>
    )
}

export default App
