import { all, call, put, takeLatest } from 'redux-saga/effects'
import { GET_COUNTRIES_REQUEST, GetCountriesRequest } from './countries.types'
import { startAction, stopAction } from '../loader/loader.actions'
import CountriesApi from '../../api/countries'
import { getCountriesSuccess } from './countries.action'

function* getCountries({ type }: GetCountriesRequest) {
    yield put(startAction(type))
    try {
        const response = yield call(CountriesApi.getCountries)
        yield put(getCountriesSuccess(response.countries))
    } catch (e) {
    } finally {
        yield put(stopAction(type))
    }
}

export default function* countriesSaga() {
    return yield all([takeLatest(GET_COUNTRIES_REQUEST, getCountries)])
}
